import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  category: [],
  error: "",
};
export const getCategory = createAsyncThunk("user/getCategory", (data) => {
  if(data){
    return API.get(ApiEndPoint.getCateogory+ "?search=" + data).then((response) => response.data);
  }else if(!data){
    return API.get(ApiEndPoint.getCateogory).then((response) => response.data);
  }
 
});
 
const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    addCategory: (state, action) => {
      var data = action.payload;
 
      API.post(ApiEndPoint.addCateogory, {
     title: data.title,
     description: data.description,
     images: data.images
      });
    },
 
    updateCategory: (state, action) => {
      var data = action.payload;
      console.log("datais",data)
      var id =data.id
      API.put(ApiEndPoint.updateCateogory + "/" + id , {
        title: data.title,
        description: data.description,
        images:data.images
      });
    },
    deleteCategory: (state, action) => {
      var data = action.payload;
      API.delete(ApiEndPoint.deleteCateogory + "/" + data);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.category = action.payload;
      state.error = "";
    });
    builder.addCase(getCategory.rejected, (state, action) => {
      state.loading = false;
      state.category = [];
      state.error = action.error.message;
    });
  },
});

export default categorySlice.reducer;
export const { addCategory, deleteCategory, updateCategory} =
  categorySlice.actions;
