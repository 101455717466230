import { React, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getVenue } from "../../../reduxSlice/venueSlice";
function VenueHeader() {
  const [state, setState] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVenue(state));
  }, [dispatch, state]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-between page-header-layout">
        <div className="search-bar">
          <Form.Group className="form-group " controlId="ProgramInputSearch">
            <Form.Control
              type="text"
              name="title"
              placeholder="Search by name..."
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Form.Group>
          <i class="fa fa-search"></i>
        </div>
        <div className="filter-bar">
          <button className="button-secondary">
            Filter <i class="fa fa-sliders"></i>
          </button>
          <button className="button-secondary">
            Sort By <i class="fa fa-sort"></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default VenueHeader;
