export const ApiEndPoint = {
  login: "dashboard/login",
  register: "dashboard/signUp",
  addCateogory: "common/addCategory",
  getCateogory: "common/getCategory",
  addSubCateogory: "common/addSubCategory",
  getSubCateogory: "common/getSubCategory",
  uploadImage: "common/uploadImage",
  addPosts: "v1/posts/step1",
  addpostStep2: "v1/posts/step2",
  addpostStep3: "v1/posts/step3",
  addpostStep4: "v1/posts/step4",
  getPosts: "v1/posts",
  deleteCateogory: "common/deleteCategory",
  deleteSubCateogory: "common/deleteSubCategory",
  updateCateogory: "common/updateCategory",
  updateSubCateogory: "common/updateSubCategory",
  addTeamMember: "v1/team",
  getMember: "v1/team",
  addVenue: "/v1/venue",
  addArticle: "/v1/article",
  addDocument: "/v1/documents",
  countDocument: "/v1/documentsCount",
  getUsers: "v1/team",
};
