import Layout from "../../../layout/layout";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AddProgramCard from "../addProgramCard";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import InputText from "../../../components/input/inputText";
import { addProgramstep4 } from "../../../reduxSlice/programSlice";

function RegistrationAndEmail() {
  const [registrationEmail, setRegistrationEmail] = useState(false);
  const [preScreeningEmail, setPreScreeningEmail] = useState(false);
  const [preIntegrationInterview, setPreIntegrationInterview] = useState(false);
  const [registrationFields, setRegistrationFields] = useState([]);
  const [maximumCapacity, setMaximumCapacity] = useState();
  const [registrationOpeningDate, setRegistrationOpeningDate] = useState();
  const [waitingList, setWaitingList] = useState("Enable");

  const [formValidation, setFormValidation] = useState(true);

  const [activeButton, setActiveButton] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const handleSubmitData = () => {
    var data = {
      registrationEmail: registrationEmail,
      preScreeningEmail: preScreeningEmail,
      preIntegrationInterview: preIntegrationInterview,
      registrationFields: registrationFields,
      maximumCapacity: maximumCapacity,
      registrationOpeningDate: Date.parse(registrationOpeningDate),
      waitingList: waitingList,
    };
    if (!formValidation) {
      dispatch(addProgramstep4(data));
      setTimeout(() => {
        navigate("/program");
      }, 1000);
    } else {
      alert("All fields are required");
    }
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    if (checked) {
      setRegistrationFields([...registrationFields, id]);
    } else {
      const updatedValues = registrationFields.filter((value) => value !== id);
      setRegistrationFields(updatedValues);
    }
  };

  const buttons = [
    { id: 1, label: "Pre Screening", date: "24-30 June, 2023" },
    { id: 2, label: "Pre integration", date: "30-02 July, 2023" },
    { id: 3, label: "PROGRAM", date: "10-16 July, 2023" },
    { id: 4, label: "POST integration", date: "20-22 July, 2023" },
  ];
  const tabs = [
    {
      id: 1,
      name: "About Program",
      active: false,
      url: "/program/aboutProgram",
    },
    {
      id: 1,
      name: "Interviews & Forms",
      active: false,
      url: "/program/interviewsAndForms",
    },
    { id: 1, name: "Pricing", active: false, url: "/program/pricing" },
    {
      id: 1,
      name: "Registration & Email",
      active: true,
      url: "/program/registrationAndEmail",
    },
  ];

  useEffect(() => {
    setFormValidation(
      !(
        registrationFields &&
        maximumCapacity &&
        registrationOpeningDate &&
        waitingList
      )
    );
  }, [
    registrationFields,
    maximumCapacity,
    registrationOpeningDate,
    waitingList,
  ]);

  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/program">
                  <i class="fa fa-chevron-left"></i> Programs ·{" "}
                  <span>Detail Page</span>
                </Link>
              </div>
              <div className="select-buttons my-3">
                <div className="button-container">
                  {buttons.map((button) => (
                    <div>
                      <button
                        key={button.id}
                        className={`button custom-btn ${
                          activeButton === button.id ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(button.id)}
                      >
                        {button.label}
                      </button>
                      <span className="button-date">{button.date}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="program-details-tabs">
                <ul>
                  {tabs.map((tab) => (
                    <li key={tab.id} className={tab.active ? "active" : ""}>
                      <Link to={tab.url}>{tab.name}</Link>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleSubmitData()}
                  className="custom-btn button-primary"
                >
                  Save Changes
                </button>
              </div>

              {/* main content  */}

              <div className="d-flex flex-column gap-3 mt-3">
                <div className="d-flex gap-3">
                  <AddProgramCard
                    className="w-50"
                    header="registration Email"
                    body={
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex gap-3">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="registration-email"
                            value={true}
                            checked={registrationEmail === true}
                            onChange={() => setRegistrationEmail(true)}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="registration-email"
                            value={false}
                            checked={registrationEmail === false}
                            onChange={() => setRegistrationEmail(false)}
                          />
                        </div>
                      </div>
                    }
                  />

                  <AddProgramCard
                    className="w-50"
                    header="pre screening Email"
                    body={
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex gap-3">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="setPreScreeningEmail"
                            value={true}
                            checked={preScreeningEmail === true}
                            onChange={() => setPreScreeningEmail(true)}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="setPreScreeningEmail"
                            value={false}
                            checked={preScreeningEmail === false}
                            onChange={() => setPreScreeningEmail(false)}
                          />
                        </div>
                      </div>
                    }
                  />
                </div>
                <div className="d-flex gap-3">
                  <AddProgramCard
                    className="w-50"
                    header="Pre INTERGRATION interview"
                    body={
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex gap-3">
                          <Form.Check
                            type="radio"
                            label="Yes"
                            name="setPreIntegrationInterview"
                            value={true}
                            checked={preIntegrationInterview === true}
                            onChange={() => setPreIntegrationInterview(true)}
                          />
                          <Form.Check
                            type="radio"
                            label="No"
                            name="setPreIntegrationInterview"
                            value={false}
                            checked={preIntegrationInterview === false}
                            onChange={() => setPreIntegrationInterview(false)}
                          />
                        </div>
                      </div>
                    }
                  />
                  {/* <AddProgramCard
                    className="w-50"
                    header="POST iNTERGRATION interview"
                    body={
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex gap-3">
                          <Form.Check
                            type="checkbox"
                            label="Yes"
                            id="yes"
                            name="yes-no"
                            checked={selectedOption === "yes"}
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            type="checkbox"
                            label="No"
                            id="no"
                            name="yes-no"
                            checked={selectedOption === "no"}
                            onChange={handleRadioChange}
                          />
                        </div>
                      </div>
                    }
                  /> */}
                </div>

                <div className="d-flex gap-3">
                  <AddProgramCard
                    className="w-50"
                    header="Documents Needed"
                    body={
                      <div>
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex flex-wrap">
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="first-name"
                              label="First Name"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="last-name"
                              label="Last Name"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="email-id"
                              label="Email id"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="confirm-email"
                              label="Confirm Email"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="phone-number"
                              label="Phone number"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="country"
                              label="Country"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="birth-date"
                              label="Birth Date"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="gender"
                              label="Gender"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="diet-prefrence"
                              label="Diet Prefrence"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50 mb-2"
                              type="checkbox"
                              id="emergency-user-name"
                              label="Emergency User Name"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Check
                              className="w-50"
                              type="checkbox"
                              id="emergency-phone-number"
                              label="Emergency Phone Number"
                              onChange={(e) => handleCheckboxChange(e)}
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />

                  <AddProgramCard
                    className="w-50"
                    body={
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex gap-3">
                          <InputText
                            type="date"
                            inputStyle="w-50"
                            label="Registration Opening Date"
                            onChange={(e) =>
                              setRegistrationOpeningDate(e.target.value)
                            }
                            required={true}
                          />
                          <InputText
                            type="text"
                            inputStyle="w-50"
                            label="Maximum Capacity"
                            onChange={(e) => setMaximumCapacity(e.target.value)}
                            required={true}
                            numbers={true}
                          />
                        </div>
                        <div>
                          <label htmlFor="" className="form-label">
                            Waiting List
                          </label>
                          <Form.Select
                            name="waiting list"
                            onChange={(e) => setWaitingList(e.target.value)}
                          >
                            <option value="Enable">Enable</option>
                            <option value="Disable">Disable</option>
                          </Form.Select>
                        </div>
                      </div>
                    }
                  />
                </div>

                <div className="d-flex justify-content-end gap-3">
                  <Link
                    to="/program/pricing"
                    className="custom-btn button-secondary "
                  >
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default RegistrationAndEmail;
