import { React, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SlideOver from "../../components/slideOver/slideOver";

import Carousel from "react-bootstrap/Carousel";

import {
  deleteCategory,
  getCategory,
  updateCategory,
} from "../../reduxSlice/categorySlice";
import Form from "react-bootstrap/Form";

import { Link } from "react-router-dom";
import InputText from "../input/inputText";
import InputDescription from "../input/inputDescription";
import UploadImage from "../../helperFunction/uploadImage";
function CategoryList(props) {
  const dispatch = useDispatch();

  const category = props.category;
  const [showSlidover, setShowSlideover] = useState(false);

  const handleSlideoverClose = () => setShowSlideover(false);
  const handleSlideover = (item) => {
    setData(item);
    setShowSlideover(true);
  };

  const [updateData, setUpdateData] = useState("");

  const [updatetitle, setUpdateTitle] = useState("");
  const [updatedescription, setUpdateDescription] = useState("");
  const [images, setImages] = useState([]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showError, setShowError] = useState("");

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  function updateCat(e) {
    e.preventDefault();
    const result = images.map(({ type, _id, ...rest }) => ({ ...rest }));

    const data = {
      id: updateData._id,
      title: updatetitle,
      description: updatedescription,
      images: result,
    };
    if (!isSubmitDisabled) {
      dispatch(updateCategory(data));
      setTimeout(() => {
        dispatch(getCategory());
      }, 200);
      handleSlideoverClose();
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  }
  function setData(item) {
    setUpdateData(item);
  }
  function deleteCat(id) {
    dispatch(deleteCategory(id));
    setTimeout(() => {
      dispatch(getCategory());
    }, 200);
  }

  const uploadImage = async (e) => {
    const imageURL = await UploadImage(e.target.files[0]);
    if (imageURL) {
      setImages([...images, imageURL]);
    } else {
      console.log("Image is not uploaded");
    }
  };

  useEffect(() => {
    setImages(updateData.images);
    setUpdateTitle(updateData.title);
    setUpdateDescription(updateData.description);
  }, [updateData]);

  useEffect(() => {
    setIsSubmitDisabled(
      !(updatetitle && updatedescription && images.length > 0)
    );
  }, [updatetitle, updatedescription, images]);

  return (
    <>
      <div>
        {category.map((item, i) => (
          <div key={item._id} className="category-list">
            <Carousel
              controls={false}
              slide={false}
              className="category-list-image"
            >
              {item.images.map((item) => (
                <Carousel.Item key={item} interval={2000}>
                  <div>
                    <img src={item.original} alt="" />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="category-list-details">
              <h3 className="category-list-header">{item.title}</h3>
              <p className="category-list-description">{item.description}</p>
              <div className=" d-flex gap-3">
                <button
                  className="category-list-buttons"
                  onClick={() => handleSlideover(item)}
                >
                  <i class="fa fa-pencil"></i> Edit
                </button>
                <SlideOver
                  show={showSlidover}
                  handleClose={handleSlideoverClose}
                  header="Edit Category"
                  content={
                    updateData ? (
                      <>
                        <div className="edit-category media-slide-over">
                          <div className="image-section">
                            <ul>
                              {images ? (
                                <>
                                  {images.map((item, index) => {
                                    return (
                                      <li key={item}>
                                        <button
                                          className="remove-btn"
                                          type="button"
                                          onClick={() =>
                                            handleRemoveImage(index)
                                          }
                                        >
                                          <i className="fa fa-close absolute top-0 right-0"></i>
                                        </button>

                                        <img src={item.original} alt="" />
                                      </li>
                                    );
                                  })}
                                </>
                              ) : (
                                <></>
                              )}

                              <li>
                                <Form.Group
                                  className="slide-over-file-input"
                                  controlId="slideOverImage"
                                >
                                  <Form.Label>
                                    <i
                                      className="fa fa-camera"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Image/Video
                                  </Form.Label>

                                  <Form.Control
                                    type="file"
                                    name="picURL"
                                    placeholder="Type here..."
                                    onChange={(e) => uploadImage(e)}
                                  />
                                </Form.Group>
                              </li>
                            </ul>
                          </div>
                          <Form>
                            <InputText
                              label="Title"
                              type="text"
                              required={true}
                              value={updatetitle}
                              autoFocus
                              onChange={(e) => setUpdateTitle(e.target.value)}
                              inputStyle="my-3"
                            />
                            <InputDescription
                              label="Description"
                              required={true}
                              value={updatedescription}
                              rows={3}
                              onChange={(e) =>
                                setUpdateDescription(e.target.value)
                              }
                            />

                            {/* <Form.Group
                              className="mb-3 custom-form-input d-flex align-items-center gap-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Check
                                type="checkbox"
                                id="checkbox1"
                                label="Add Category to Home Screen"
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3 custom-form-input d-flex align-items-center gap-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Check
                                type="checkbox"
                                id="checkbox2"
                                label="Add Category to menu"
                              />
                            </Form.Group> */}

                            {showError ? (
                              <small className="form-error">{showError}</small>
                            ) : (
                              <></>
                            )}
                            <button
                              className="custom-btn button-primary w-100 mt-4"
                              onClick={(e) => {
                                updateCat(e);
                              }}
                            >
                              Update
                            </button>
                          </Form>
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  }
                />

                <Link
                  className="category-list-buttons"
                  to={`/settings/category/subCategory/${item._id}`}
                >
                  {" "}
                  <i class="fa fa-eye"></i> View Sub Cat
                </Link>

                <button
                  className="category-list-buttons"
                  onClick={() => deleteCat(item._id)}
                >
                  <i class="fa fa-trash"></i> Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default CategoryList;
