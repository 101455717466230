import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { getCategory, addCategory } from "../../reduxSlice/categorySlice";
import SlideOver from "../slideOver/slideOver";
import InputText from "../input/inputText";
import InputDescription from "../input/inputDescription";
import UploadImage from "../../helperFunction/uploadImage";
function AddCategory() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [showError, setShowError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  var data = {
    title,
    description,
    images,
  };
  const uploadImage = async (e) => {
    const image = await UploadImage(e.target.files[0]);
    if (image) {
      setImages([...images, image]);
    } else {
      console.log("Image upload failed");
    }
  };
  function addData(e) {
    e.preventDefault();
    if (!isSubmitDisabled) {
      dispatch(addCategory(data));
      handleClose();
      setTitle("");
      setDescription("");
      setImages([]);
      setTimeout(() => {
        dispatch(getCategory());
      }, 100);
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  }
  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  useEffect(() => {
    setIsSubmitDisabled(!(title && description && images.length > 0));
  }, [title, description, images]);
  return (
    <>
      <button
        className="custom-btn button-primary"
        onClick={() => handleShow()}
      >
        <i class="fa fa-plus"></i> Add New
      </button>
      <SlideOver
        show={show}
        handleClose={handleClose}
        header="Add Category"
        content={
          <>
            <div className="edit-category media-slide-over">
              <div className="image-section">
                <ul>
                  {images ? (
                    <>
                      {images.map((item, index) => {
                        return (
                          <li key={item}>
                            <button
                              className="remove-btn"
                              type="button"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <i className="fa fa-close absolute top-0 right-0"></i>
                            </button>

                            <img src={item.original} alt="" />
                          </li>
                        );
                      })}
                    </>
                  ) : (
                    <></>
                  )}

                  <li>
                    <Form.Group
                      className="slide-over-file-input"
                      controlId="slideOverImage"
                    >
                      <Form.Label>
                        <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                        Image/Video
                      </Form.Label>

                      <Form.Control
                        type="file"
                        name="picURL"
                        placeholder="Type here..."
                        onChange={(e) => uploadImage(e)}
                      />
                    </Form.Group>
                  </li>
                </ul>
              </div>
              <Form>
                <InputText
                  label="Title"
                  type="text"
                  required={true}
                  value={title}
                  autoFocus
                  onChange={(e) => setTitle(e.target.value)}
                  inputStyle="my-3"
                />
                <InputDescription
                  label="Description"
                  required={true}
                  value={description}
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />

                <Form.Group
                  className="mb-3 custom-form-input d-flex align-items-center gap-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Check
                    type="checkbox"
                    id="checkbox1"
                    label="Add Category to Home Screen"
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3 custom-form-input d-flex align-items-center gap-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Check
                    type="checkbox"
                    id="checkbox2"
                    label="Add Category to menu"
                  />
                </Form.Group>

                {showError ? (
                  <small className="form-error">{showError}</small>
                ) : (
                  <></>
                )}
                <button
                  className="custom-btn button-primary w-100"
                  type="button"
                  onClick={(e) => {
                    addData(e);
                  }}
                >
                  Submit
                </button>
              </Form>
            </div>
          </>
        }
      />
    </>
  );
}

export default AddCategory;
