import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Settings from "../settings/settings";
import SlideOver from "../../components/slideOver/slideOver";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getSubCategory,
  addSubCategory,
} from "../../reduxSlice/subCateogrySlice";
import InputText from "../../components/input/inputText";
import InputDescription from "../../components/input/inputDescription";
import SubCategoryList from "../../components/subCategory/subCategoryList";
import UploadImage from "../../helperFunction/uploadImage";

function SubCategory() {
  const dispatch = useDispatch();
  const params = useParams();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [state, setState] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [showError, setShowError] = useState("");

  const uploadImage = async (e) => {
    const image = await UploadImage(e.target.files[0]);
    if (image) {
      setImages([...images, image]);
    } else {
      console.log("Image upload failed");
    }
  };
  function addData(e) {
    e.preventDefault();
    var data = {
      title,
      description,
      params,
      images,
    };
    if (!isSubmitDisabled) {
      dispatch(addSubCategory(data));
      setTitle("");
      setDescription("");
      setTimeout(() => {
        dispatch(getSubCategory(params.id));
      }, 200);
      handleClose();
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  }

  useEffect(() => {
    setIsSubmitDisabled(!(title && description && images.length > 0));
  }, [title, description, images]);

  useEffect(() => {
    var data = {
      id: params.id,
      search: state,
    };
    dispatch(getSubCategory(data));
  }, [dispatch, state, params.id]);

  useEffect(() => {
    dispatch(getSubCategory(params.id));
  }, [dispatch, params.id]);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h3 className="page-header">Sub Categories</h3>
              </div>
              <div>
                <button
                  onClick={() => setShow(true)}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Sub Category"
                  content={
                    <div className="media-slide-over">
                      <div className="image-section">
                        <ul>
                          {images ? (
                            <>
                              {images.map((item, index) => {
                                return (
                                  <li>
                                    <img src={item.original} alt="" />
                                  </li>
                                );
                              })}
                            </>
                          ) : (
                            <></>
                          )}
                          <li>
                            <Form.Group
                              className="slide-over-file-input"
                              controlId="slideOverImage"
                            >
                              <Form.Label>
                                <i
                                  className="fa fa-camera"
                                  aria-hidden="true"
                                ></i>{" "}
                                Image/Video
                              </Form.Label>

                              <Form.Control
                                type="file"
                                name="picURL"
                                placeholder="Type here..."
                                onChange={(e) => uploadImage(e)}
                              />
                            </Form.Group>
                          </li>
                        </ul>
                      </div>
                      <Form>
                        <InputText
                          label="Title"
                          type="text"
                          required={true}
                          value={title}
                          autoFocus
                          onChange={(e) => setTitle(e.target.value)}
                          inputStyle="my-3"
                        />
                        <InputDescription
                          label="Description"
                          required={true}
                          value={description}
                          rows={5}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        {showError ? (
                          <small className="form-error mt-2">{showError}</small>
                        ) : (
                          <></>
                        )}
                        <button
                          className="custom-btn button-primary w-100 mt-3"
                          onClick={(e) => {
                            addData(e);
                          }}
                        >
                          Submit
                        </button>
                      </Form>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Form.Group>
                <i class="fa fa-search"></i>
              </div>
              <div className="filter-bar">
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <SubCategoryList />
          </div>
        </div>
      </Settings>
    </>
  );
}

export default SubCategory;
