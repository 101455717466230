import API from "../utils/baseUrls";
import { ApiEndPoint } from "../utils/apiEndPoints";
const UploadImage = async (e, func, index) => {
  const formData = new FormData();
  formData.append("image", e);

  try {
    const response = await API.post(ApiEndPoint.uploadImage, formData);

    const imageUrl = response.data;
    return imageUrl;
  } catch (error) {
    console.log(error);
  }
};
export default UploadImage;
