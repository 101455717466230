import { React } from "react";
import Header from "../components/header/header";
import Sidebar from "../components/sidebar/sidebar";
function Layout({ children }) {
  return (
    <div className="">
      <div>
        <Header />
      </div>
      <div className="container">
        <div className="layout-main">
          <div className="sidebar-wrapper d-none d-lg-block relative">
            <Sidebar />
          </div>
          <div className="main-wrapper ps-lg-3">
            <div className="main-content">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
