import Layout from "../../../layout/layout";
import React, { useEffect, useState } from "react";

import AddProgramCard from "../addProgramCard";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";

import { getCategory } from "../../../reduxSlice/categorySlice";
import { getSubCategory } from "../../../reduxSlice/subCateogrySlice";
import { getTeamMember } from "../../../reduxSlice/addTeamMemberSlice";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { addProgram } from "../../../reduxSlice/programSlice";
import InputText from "../../../components/input/inputText";
import InputDescription from "../../../components/input/inputDescription";
import UploadImage from "../../../helperFunction/uploadImage";
import moment from "moment";
import { Select } from "antd";
import Toast from "../../../components/toastAlert/toast";
function AboutProgram() {
  const [activeButton, setActiveButton] = useState(null);
  const [searchTeam, setSearchTeam] = useState("");
  const [selectedMember, setSelectedMember] = useState([]);
  const [selectedTeamData, setSelectedTeamData] = useState([]);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [description, setDescription] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");
  const [title, setTitle] = useState("");
  const [programType, setProgramType] = useState("");
  const [showNext1, setShowNext1] = useState(false);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };
  const buttons = [
    { id: 1, label: "Pre Screening", date: "24-30 June, 2023" },
    { id: 2, label: "Pre integration", date: "30-02 July, 2023" },
    { id: 3, label: "PROGRAM", date: "10-16 July, 2023" },
    { id: 4, label: "POST integration", date: "20-22 July, 2023" },
  ];
  const tabs = [
    {
      id: 1,
      name: "About Program",
      active: true,
      url: "/program/aboutProgram",
    },
    {
      id: 1,
      name: "Interviews & Forms",
      active: false,
      url: "/program/interviewsAndForms",
    },
    { id: 1, name: "Pricing", active: false, url: "/program/pricing" },
    {
      id: 1,
      name: "Registration & Email",
      active: false,
      url: "/program/registrationAndEmail",
    },
  ];

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [images, setImages] = useState([]);
  const [formValidation, setFormValidation] = useState(true);
  const [toastMessage, setToastMessage] = useState("");
  const [toastBg, setToastBg] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [imageUploadError, setImageUploadError] = useState("");

  const category = useSelector((state) => state.category.category);
  const subCategory = useSelector((state) => state.subCategory.subCategory);
  const team = useSelector((state) => state.teamMeamber.teamMember);

  useEffect(() => {
    const filterMember = selectedMember.map((_id) =>
      team.find((el) => el._id === _id)
    );
    setSelectedTeamData(filterMember);
  }, [selectedMember, team]);

  const uploadImage = async (e) => {
    const imageURL = await UploadImage(e.target.files[0]);

    if (imageURL) {
      setImages([...images, imageURL]);
      setImageUploadError("");
    } else {
      setImageUploadError("Image upload failed");
    }
  };

  function categoryFun(idVal) {
    var data = {
      id: idVal,
      search: "",
    };
    dispatch(getSubCategory(data));
  }

  const handleSelectedMember = (e) => {
    const { id, checked } = e.target;
    if (checked) {
      setSelectedMember([...selectedMember, id]);
    } else {
      const updatedValues = selectedMember.filter((value) => value !== id);
      setSelectedMember(updatedValues);
    }
  };
  const handleRemoveMember = (item, index) => {
    const data = [...selectedMember];
    data.splice(index, 1);
    setSelectedMember(data);
  };

  const handleCloseToast = () => {
    setShowToast(false);
  };

  useEffect(() => {
    dispatch(getCategory());
    var data = {
      type: "normal",
      dataValue: "",
    };
    dispatch(getTeamMember(data));
  }, [dispatch, images]);

  useEffect(() => {
    var data = {};
    if (searchTeam.length > 1) {
      data = {
        type: "search",
        dataValue: searchTeam,
      };
    } else {
      data = {
        type: "normal",
        dataValue: "",
      };
    }
    dispatch(getTeamMember(data));
  }, [dispatch, searchTeam]);

  const saveStep1 = (e) => {
    e.preventDefault();
    var data = {
      categoryId: categoryId,
      subCategoryId: subCategoryId,
      title: title,
      description: description,
      type: programType,
      numberOfDays: 1,
      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),
      teamMembers: selectedMember,
      picURL: images,
    };
    if (!formValidation) {
      dispatch(addProgram(data));
      setShowNext1(true);
      setShowToast(true);
      setToastBg("bg-success");
      setToastMessage("Form submitted successfully");
    } else {
      setShowToast(true);
      setToastBg("bg-danger");
      setToastMessage("All fields are required");
      if (images.length === 0) {
        setImageUploadError("Image field is required");
      } else {
        setImageUploadError("");
      }
    }
  };
  const handleSubCategoryChange = (newValue) => {
    setSubCategoryId(newValue);
  };
  const handleCategoryChange = (value) => {
    categoryFun(value);
    setCategoryId(value);
  };

  useEffect(() => {
    setFormValidation(
      !(
        title &&
        description &&
        categoryId &&
        subCategoryId &&
        programType &&
        endDate &&
        startDate &&
        selectedMember &&
        images.length > 0
      )
    );
  }, [
    title,
    images,
    description,
    subCategoryId,
    categoryId,
    programType,
    startDate,
    endDate,
    selectedMember,
  ]);

  return (
    <>
      <Toast
        handleClose={handleCloseToast}
        show={showToast}
        message={toastMessage}
        bg={toastBg}
      />
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/program">
                  <i class="fa fa-chevron-left"></i> Programs ·{" "}
                  <span>Detail Page</span>
                </Link>
              </div>
              <div className="select-buttons my-3">
                <div className="button-container">
                  {buttons.map((button) => (
                    <div>
                      <button
                        key={button.id}
                        className={`button custom-btn ${
                          activeButton === button.id ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(button.id)}
                      >
                        {button.label}
                      </button>
                      <span className="button-date">{button.date}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="program-details-tabs mb-4">
                <ul>
                  {tabs.map((tab, index) => (
                    <li key={tab.id} className={tab.active ? "active" : ""}>
                      <Link
                        className={showNext1 && index === 1 ? "" : "pe-none"}
                        to={tab.url}
                      >
                        {tab.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={(e) => {
                    saveStep1(e);
                  }}
                  className="custom-btn button-primary"
                >
                  Save Changes
                </button>
              </div>

              {/* main content  */}

              <div className="d-flex gap-3 p-0 mt-3">
                <form className="w-75 d-flex flex-column gap-3">
                  <InputText
                    type="text"
                    name="title"
                    inputStyle="about-program-title"
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    placeholder="Title here..."
                    required={true}
                  />

                  <div className="d-flex gap-3">
                    <div className="w-50">
                      <AddProgramCard
                        header="Select Category"
                        body={
                          <Select
                            placeholder="Search to Select"
                            optionFilterProp="title"
                            options={category.map((i) => ({
                              label: i.title,
                              value: i._id,
                            }))}
                            value={categoryId}
                            onChange={handleCategoryChange}
                          />
                        }
                      />
                    </div>
                    <div className="w-50">
                      <AddProgramCard
                        header="Select SubCategory"
                        body={
                          <>
                            <Select
                              placeholder="Search to Select"
                              optionFilterProp="title"
                              options={subCategory.map((i) => ({
                                label: i.title,
                                value: i._id,
                              }))}
                              value={subCategoryId}
                              onChange={handleSubCategoryChange}
                            />
                          </>
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex gap-3">
                    <div className="w-50">
                      <AddProgramCard
                        header="Program Type & Date"
                        body={
                          <div>
                            <Form.Select
                              as="select"
                              onChange={(e) => setProgramType(e.target.value)}
                            >
                              <option value="Fixed Dates">Fixed Dates</option>
                              <option value="Package">Package</option>
                              <option value="Flexible Dates">
                                Flexible Dates
                              </option>
                              <option value="Single Consultation">
                                Single Consultation
                              </option>
                            </Form.Select>
                            <div className="d-flex gap-3 mt-3">
                              <InputText
                                type="date"
                                label="Start Date"
                                name="startDate"
                                format="YYYY-MM-DD"
                                inputStyle="w-50"
                                onChange={(e) => {
                                  setStartDate(e.target.value);
                                }}
                                required={true}
                              />
                              <InputText
                                type="date"
                                label="End Date"
                                name="endDate"
                                format="YYYY-MM-DD"
                                inputStyle="w-50"
                                onChange={(e) => {
                                  setEndDate(e.target.value);
                                }}
                                required={true}
                              />
                            </div>
                            <p className="card-desc">
                              When does this event begin and end?
                            </p>
                          </div>
                        }
                      />
                    </div>
                    <div className="w-50">
                      <AddProgramCard
                        header="Team Members"
                        body={
                          <div>
                            <div className="d-flex flex-column gap-2">
                              {selectedTeamData.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="border rounded px-3 py-2 d-flex align-items-center justify-content-between"
                                  >
                                    <span>
                                      {item.fullName}.{item.creator[0]}
                                    </span>
                                    <button
                                      className="btn-icon"
                                      onClick={() => {
                                        handleRemoveMember(item, index);
                                      }}
                                    >
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="d-flex mt-3">
                              <button
                                type="button"
                                onClick={handleShow}
                                className="ms-auto plain-button"
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Add Member
                              </button>
                              <Modal
                                className="common-create-modal"
                                show={show}
                                onHide={handleClose}
                              >
                                <Container className="add-member-modal">
                                  <div className="modal-header">
                                    <h3 className="text-center w-100">
                                      Our Team
                                    </h3>
                                  </div>
                                  <div className="modal-body">
                                    <div className="body-filter d-flex gap-3">
                                      <Form.Group
                                        className="form-group w-50 about-program-input"
                                        controlId="ProgramInputSearch"
                                      >
                                        <Form.Control
                                          type="text"
                                          name="title"
                                          placeholder="Type here..."
                                          onChange={(e) => {
                                            setSearchTeam(e.target.value);
                                          }}
                                        />
                                      </Form.Group>
                                      <Form.Select
                                        className="w-50"
                                        aria-label="Default select example"
                                      >
                                        <option>All Members</option>
                                        <option value="1">In Active</option>
                                      </Form.Select>
                                    </div>
                                    <div className="member-lists mt-2">
                                      <ul>
                                        {team.map((member) => (
                                          <li
                                            key={member}
                                            className="member-list"
                                          >
                                            <img
                                              className="image"
                                              src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png"
                                              alt=""
                                            />
                                            <div className="member-details">
                                              <strong className="member-title">
                                                {member.fullName}
                                              </strong>
                                              <span className="member-email">
                                                {member.email}
                                              </span>
                                            </div>
                                            <Form.Check
                                              value={member._id}
                                              className="ms-auto"
                                              type="checkbox"
                                              id={member._id}
                                              onChange={(e) => {
                                                handleSelectedMember(e);
                                              }}
                                            />
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                    <div className="mt-2">
                                      <button
                                        onClick={() => handleClose()}
                                        className="custom-btn button-primary w-100"
                                      >
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </Container>
                              </Modal>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <AddProgramCard
                    header="Description"
                    body={
                      <div>
                        <InputDescription
                          name="description"
                          rows={4}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          placeholder="Type here..."
                          required={true}
                        />
                      </div>
                    }
                  />
                  {showNext1 ? (
                    <div className="d-flex">
                      <Link
                        to="/program/interviewsAndForms"
                        className="custom-btn button-primary ms-auto"
                      >
                        Next
                      </Link>
                    </div>
                  ) : null}
                </form>
                <div className="w-25 d-flex flex-column gap-3">
                  {/* <AddProgramCard
                    header="Registrations"
                    body={
                      <div>
                        <div className="d-flex gap-2 align-items-center registration">
                          <div className="images">
                            {[1, 2, 3, 4].map((item) => (
                              <img
                                key={item}
                                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                alt=""
                              />
                            ))}
                          </div>
                          <span>24 People Registered</span>
                        </div>
                      </div>
                    }
                  /> */}
                  <AddProgramCard
                    header="Featured Images/Videos"
                    body={
                      <div className="add-program-feature">
                        <div className="images">
                          <ul>
                            {images.map((e, i) => {
                              return (
                                <li key={i}>
                                  <img src={e.original} alt="" />
                                </li>
                              );
                            })}
                            <li>
                              <Form.Group
                                className="input-file"
                                controlId="uploadImage"
                              >
                                <Form.Label>
                                  <i className="fa fa-camera"></i>
                                </Form.Label>

                                <Form.Control
                                  type="file"
                                  name="picURL"
                                  onChange={(e) => {
                                    console.log(e);
                                    uploadImage(e);
                                  }}
                                  placeholder="Type here..."
                                />
                              </Form.Group>
                            </li>
                          </ul>
                          <p className="card-desc m-0">
                            Click the image to edit or update
                          </p>
                          {imageUploadError ? (
                            <small class="form-error">{imageUploadError}</small>
                          ) : null}
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default AboutProgram;
