import Dashboard from "./pages/dashboard/dashboard";
import Category from "./pages/category/category";
import Program from "./pages/program/program";
import SubCategory from "./pages/sub-category/subCategory";
import Login from "./pages/login/login";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute/protectedRoute";
import Venue from "./pages/venue/venue";
import Products from "./pages/products/product";
import OurTeam from "./pages/our-team/ourTeam";
import AddTeamMember from "./pages/our-team/addTeamMember";
import Settings from "./pages/settings/settings";
import Articles from "./pages/articles/articles";
import Media from "./pages/media/media";
import Interviewers from "./pages/interviewers/interviewers";
import Documents from "./pages/documents/documents";
import Emails from "./pages/email/emails";
import PaymentTerms from "./pages/payment-terms/paymentTerms";
import AboutProgram from "./pages/program/programDetails/aboutProgram";
import EditTeamMember from "./pages/our-team/editTeamMember";
import InterviewsAndForms from "./pages/program/programDetails/interviewsAndForms";
import Pricing from "./pages/program/programDetails/pricing";
import RegistrationAndEmail from "./pages/program/programDetails/registrationAndEmail";
import GuestListing from "./pages/guests/guestListing";
import EditGuest from "./pages/guests/editGuest";
import Users from "./pages/users/users";
import AddProduct from "./pages/products/subComponent/addProduct";
import EditProduct from "./pages/products/subComponent/editProduct";
import Forms from "./pages/documents/forms";
import Register from "./pages/register/register";
import AddUser from "./pages/users/addUser";
import EditUser from "./pages/users/editUser";
function App() {
  return (
    <>
      <div className="main-layout">
        <Routes>
          <Route strict path="/" element={<Login />} />
          <Route strict path="/register" element={<Register />} />
          <Route
            strict
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/category"
            element={
              <ProtectedRoute>
                <Category />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program"
            element={
              <ProtectedRoute>
                <Program />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/aboutProgram"
            element={
              <ProtectedRoute>
                <AboutProgram />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/interviewsAndForms"
            element={
              <ProtectedRoute>
                <InterviewsAndForms />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/pricing"
            element={
              <ProtectedRoute>
                <Pricing />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/registrationAndEmail"
            element={
              <ProtectedRoute>
                <RegistrationAndEmail />
              </ProtectedRoute>
            }
          />

          <Route
            strict
            path="/venue"
            element={
              <ProtectedRoute>
                <Venue />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/products"
            element={
              <ProtectedRoute>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/ourTeam"
            element={
              <ProtectedRoute>
                <OurTeam />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/ourTeam/addTeamMember"
            element={
              <ProtectedRoute>
                <AddTeamMember />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/ourTeam/editTeamMember/:id"
            element={
              <ProtectedRoute>
                <EditTeamMember />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings"
            element={
              <ProtectedRoute>
                <Settings />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/category/subCategory/:id"
            element={
              <ProtectedRoute>
                <SubCategory />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/articles"
            element={
              <ProtectedRoute>
                <Articles />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/media"
            element={
              <ProtectedRoute>
                <Media />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/interviewers"
            element={
              <ProtectedRoute>
                <Interviewers />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/documents"
            element={
              <ProtectedRoute>
                <Documents />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/forms"
            element={
              <ProtectedRoute>
                <Forms />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/emails"
            element={
              <ProtectedRoute>
                <Emails />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/settings/paymentTerms"
            element={
              <ProtectedRoute>
                <PaymentTerms />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/guestListing"
            element={
              <ProtectedRoute>
                <GuestListing />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/program/guestListing/editGuest"
            element={
              <ProtectedRoute>
                <EditGuest />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/products/addProduct"
            element={
              <ProtectedRoute>
                <AddProduct />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/products/editProduct"
            element={
              <ProtectedRoute>
                <EditProduct />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/users/addUser"
            element={
              <ProtectedRoute>
                <AddUser />
              </ProtectedRoute>
            }
          />
          <Route
            strict
            path="/users/editUser/:id"
            element={
              <ProtectedRoute>
                <EditUser />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
