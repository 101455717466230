import Tabs from "../../components/tabs/tabs";
import UsersList from "./usersList";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../reduxSlice/usersSlice";
import { useEffect } from "react";
function UsersLayout() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  const tabs = [
    {
      id: "all-guests",
      label: "All Guests",
      active: true,
      component: <UsersList users={users} />,
    },
    {
      id: "current-guest",
      label: "Current Guests",
      active: false,
      component: <UsersList users={users} />,
    },
    {
      id: "arriving-today",
      label: "Arriving Today",
      active: false,
      component: <UsersList users={users} />,
    },
    {
      id: "leaving-today",
      label: "Leaving Today",
      active: false,
      component: <UsersList users={users} />,
    },
    {
      id: "leaving-this-week",
      label: "Leaving This Week",
      active: false,
      component: <UsersList users={users} />,
    },
  ];
  return (
    <>
      <div>
        <Tabs tabs={tabs} />
      </div>
    </>
  );
}
export default UsersLayout;
