import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import LogoutIcon from "../../assets/images/logout.png";
import notificationIcon from "../../assets/images/notify.png";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  function logout() {
    var token = localStorage.removeItem("token");
    if (!token) {
      navigate("/");
    }
    // setIsLoggedin(false);
  }
  return (
    <>
      <header className="header main-header">
        <nav className="navbar navbar-expand-sm">
          <div className="container">
            <Link className="navbar-brand ps-md-3" href="">
              <img width="140px" height="40px" src={Logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mynavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="mynavbar">
              <ul className="navbar-nav ms-auto align-items-center bg-white">
                <ul className="d-block d-md-none">
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/dashboard" ? "active" : ""
                      }`}
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/category" ? "active" : ""
                      }`}
                      to="/category"
                    >
                      Categories
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`${
                        location.pathname === "/program" ? "active" : ""
                      }`}
                      to="/program"
                    >
                      Programs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Venues</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Rooms</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Registrations</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Products</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Our Teams</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Blogs</Link>
                  </li>
                  <li className="nav-item">
                    <Link href="#">Settings</Link>
                  </li>
                  {/* <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/category" className="nav-link">
                    Category
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/program" className="nav-link">
                    Program
                  </Link>
                </li> */}
                  {/* <li className="nav-item">
                  <Link to="" className="nav-link">
                    <img src={notificationIcon} alt="" />
                  </Link>
                </li> */}
                </ul>
                <li className="nav-item d-flex align-items-center gap-2">
                  <button className="nav-link">
                    <img src={notificationIcon} alt="" />
                  </button>
                  <button onClick={logout} className="nav-link">
                    <img src={LogoutIcon} alt="" />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
