import React from "react";
import "./toast.css";
function Toast({ show, bg, message, handleClose }) {
  if (show) {
    setTimeout(() => {
      handleClose();
    }, 4000);
  }
  return (
    <>
      <div className={`toast-message ${show ? "active" : ""} ${bg}`}>
        <span>{message}</span>
        <button onClick={() => handleClose()}>
          <i className="fa fa-close"></i>
        </button>
      </div>
    </>
  );
}
export default Toast;
