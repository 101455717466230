import { React, useState } from "react";
import API from "../../utils/baseUrls";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "../../schema/index";
function LoginC() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initialValues = {
    email: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,

      onSubmit: (values, action) => {
        console.log(values, "values");
        API.post(ApiEndPoint.login, {
          email: values.email,
          password: values.password,
        })
          .then(function (response) {
            var token = response.data.access_token;
            if (!token) {
              navigate("/");
            } else {
              localStorage.setItem("token", token);
              navigate("/dashboard");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        // action.resetForm();
      },
    });
  function handleLogin() {
    // API.post(ApiEndPoint.login, {
    //   email,
    //   password,
    // })
    //   .then(function (response) {
    //     var token = response.data.access_token;
    //     if (!token) {
    //       navigate("/");
    //     } else {
    //       localStorage.setItem("token", token);
    //       navigate("/dashboard");
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  }
  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  return (
    <>
      {" "}
      <form onSubmit={handleSubmit} className="myform">
        <div className="form-group">
          <input
            type="email"
            name="email"
            className="form-control"
            placeholder="Email"
            // value={email}
            validate={validateEmail()}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            //  onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && touched.email ? (
            <label className="error">{errors.email}</label>
          ) : null}
        </div>
        <div className="form-group">
          <input
            type="password"
            name="password"
            className="form-control"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
          />{" "}
          {errors.password && touched.password ? (
            <label className="error">{errors.password}</label>
          ) : null}
        </div>

        <div className="form-group mt-3 mb-4 pb-2 text-center">
          <button type="submit" className="btn btn-block gap-5 loginButton">
            Sign In
          </button>
        </div>
        <div className="forgot-password-link text-center">
          <a href="#">Forgot Password?</a>
        </div>
      </form>
    </>
  );
}

export default LoginC;
