import { useDispatch } from "react-redux";
import {
  deleteDocument,
  getDocument,
  updateDocument,
} from "../../reduxSlice/documentSlice";
import { useEffect, useState } from "react";
import SlideOver from "../../components/slideOver/slideOver";
import Form from "react-bootstrap/Form";
import InputText from "../../components/input/inputText";
import InputDescription from "../../components/input/inputDescription";

function FormsList({ forms }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [editData, setEditData] = useState({});

  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescriptuion] = useState("");

  const [formValidation, setFormValidation] = useState(true);
  const [showError, setShowError] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (value) => {
    setShow(true);
    setEditData(value);
  };
  const handleDelete = (id) => {
    dispatch(deleteDocument(id));
    setTimeout(() => {
      dispatch(getDocument({ type: "form", search: "" }));
    }, 200);
  };
  const handleUpdateForm = (e) => {
    e.preventDefault();
    var data = {
      id: editData._id,
      title: editTitle,
      description: editDescription,
    };
    if (!formValidation) {
      dispatch(updateDocument(data));
      setTimeout(() => {
        dispatch(getDocument({ type: "form", search: "" }));
      }, 200);
      handleClose();
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };
  useEffect(() => {
    setEditTitle(editData.title);
    setEditDescriptuion(editData.description);
  }, [editData]);

  useEffect(() => {
    setFormValidation(!(editTitle && editDescription));
  }, [editTitle, editDescription]);
  return (
    <>
      <div>
        <ul className="document-layout">
          {forms ? (
            forms.map((i) => (
              <li key={i._id} className="document-list">
                <div className="d-flex align-items-center justify-content-between">
                  <h3 className="title">{i.title}</h3>
                  <div className="d-flex align-items-center gap-1">
                    <div>
                      <button
                        onClick={() => handleShow(i)}
                        className="btn-icon"
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </button>
                      <SlideOver
                        show={show}
                        handleClose={handleClose}
                        header="Update Forms"
                        content={
                          <div className="media-slide-over">
                            <Form>
                              <InputText
                                label="Title"
                                required={true}
                                type="text"
                                value={editTitle}
                                onChange={(e) => setEditTitle(e.target.value)}
                              />

                              <InputDescription
                                label="Description"
                                required={true}
                                rows={4}
                                value={editDescription}
                                onChange={(e) =>
                                  setEditDescriptuion(e.target.value)
                                }
                                inputStyle="my-3"
                              />
                              {showError ? (
                                <small className="form-error">
                                  {showError}
                                </small>
                              ) : (
                                <></>
                              )}

                              <button
                                type="submit"
                                onClick={(e) => handleUpdateForm(e)}
                                className="custom-btn button-primary w-100 mt-3"
                              >
                                Update
                              </button>
                            </Form>
                          </div>
                        }
                      />
                    </div>
                    <button
                      onClick={() => handleDelete(i._id)}
                      className="btn-icon"
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
                <p className="details">{i.description}</p>
              </li>
            ))
          ) : (
            <></>
          )}
        </ul>
      </div>
    </>
  );
}
export default FormsList;
