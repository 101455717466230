import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";

const InputDescription = ({
  label,
  rows,
  value,
  placeholder,
  name,
  onChange,
  required,
  inputStyle,
  controlId,
}) => {
  const [error, setError] = useState("");
  const [generatedControlId, setGeneratedControlId] = useState("");

  useEffect(() => {
    if (!controlId) {
      const generatedId = `input-${Math.random().toString(36).substr(2, 10)}`;
      setGeneratedControlId(generatedId);
    }
  }, [controlId]);

  const handleBlur = (e) => {
    if (!e.target.value && required) {
      if (label) {
        setError(`${label} is required`);
      } else {
        setError("This field is required.");
      }
    } else {
      setError("");
    }
  };

  return (
    <div className={`input-wrapper ${inputStyle}`}>
      <Form.Group
        className="form-group"
        controlId={controlId || generatedControlId}
      >
        {label ? <Form.Label>{label}</Form.Label> : <></>}
        <Form.Control
          name={name}
          as="textarea"
          rows={rows}
          value={value}
          onChange={(e) => onChange(e)}
          onBlur={(e) => handleBlur(e)}
          placeholder={placeholder}
        />
      </Form.Group>
      {error ? <small className="form-error">{error}</small> : <></>}
    </div>
  );
};

export default InputDescription;
