import moment from "moment";

export const formatDate = (time, format) => {
  const formattedDate = moment(time).format(format ? format : "ll");
  return formattedDate;
};
export const formatTimeDate = (time, format) => {
  const formattedDateTime = moment(time).format(format ? format : "MMM D · hA");
  return formattedDateTime;
};

export const todayDate = () => {
  const formattedDate = moment().format("ll");
  return formattedDate;
};
