import { React } from "react";
import PropTypes from "prop-types";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./slideover.css";
function SlideOver({ header, content, show, handleClose }) {
  return (
    <>
      <Offcanvas
        placement="end"
        className="slide-over-panel"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header closeButton className="slide-over-header">
          <Offcanvas.Title>{header}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{content}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

// Document the component
SlideOver.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
};

export default SlideOver;
