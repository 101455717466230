import TeamMemberCard from "./teamMemberCard";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTeamMember } from "../../../reduxSlice/addTeamMemberSlice";
function OurTeamLayout() {
  const dispatch = useDispatch();
  const teamMemebers = useSelector((state) => state.teamMeamber.teamMember);

  useEffect(() => {
    dispatch(getTeamMember());
  }, [dispatch]);

  return (
    <>
      <ul className="our-team-lists mb-3">
        <TeamMemberCard />
      </ul>
    </>
  );
}
export default OurTeamLayout;
