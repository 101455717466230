import React, { useState } from "react";
// import programeImage from "../../assets/images/programe-image.png";
// import moment from "moment";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
// import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// import API from "../../utils/baseUrls";
// import { ApiEndPoint } from "../../utils/apiEndPoints";
import { Link } from "react-router-dom";
import { formatDate } from "../../helperFunction/formatter";
import Carousel from "react-bootstrap/Carousel";
import { deleteProgram, getProgram } from "../../reduxSlice/programSlice";
function ProgramListing(props) {
  const program = props.programs.program;
  // const [latitude, setLatitude] = useState();
  // const [longitude, setLongitude] = useState();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const category = useSelector((state) => state.category.category);
  const subCategory = useSelector((state) => state.subCategory.subCategory);
  // function toggle(item) {
  //   handleShow();
  // }

  // function uploadImage(e, func) {
  //   const formData = new FormData();
  //   formData.append("image", e);

  //   API.post(ApiEndPoint.uploadImage, formData)
  //     .then(function (response) {
  //       func("picURL", [response.data]);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }

  // function subCategoryFun(e, func) {}
  function handleSubmit() {}
  const handleDeleteProgram = (id) => {
    dispatch(deleteProgram(id));
    setTimeout(() => {
      dispatch(getProgram());
    }, 200);
  };
  return (
    <>
      {program.map((item) => (
        <div className="col-12" key={item._id}>
          <div className="program-listing">
            <div className="each-list-program">
              <div className="program-header">
                <h3 className="programe-list-title">
                  {item?.title} · {formatDate(item.startDate)} -{" "}
                  {formatDate(item.endDate)}{" "}
                </h3>

                <div className="d-flex align-items-center">
                  <button className="btn programe-list-btn">
                    Pre Screening
                  </button>
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="">
                        <div className="drop">
                          <Link to="/program/guestListing">
                            <i className="fa fa-pencil"></i> Edit
                          </Link>
                        </div>
                        <Dropdown.Divider />
                        <div className="drop delete">
                          <button
                            className="btn-icon"
                            onClick={() => handleDeleteProgram(item._id)}
                          >
                            <i className="fa fa-trash"></i> Delete
                          </button>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="program-content ">
                <div className="programe-content-main row">
                  <div className="col-12 col-md-7 col-lg-6 d-flex flex-column flex-md-row align-items-center ps-0">
                    <div className="programe-media me-3">
                      {/* <img src={programeImage} alt="programe" /> */}
                      <Carousel controls={false} slide={false} className="">
                        {item.picURL ? (
                          item.picURL.map((item) => (
                            <Carousel.Item interval={2000} key={item}>
                              <div>
                                <img src={item.original} alt="" />
                              </div>
                            </Carousel.Item>
                          ))
                        ) : (
                          <></>
                        )}
                      </Carousel>
                    </div>
                    <ul className="listing-info listing-info-first">
                      <li>
                        <span>type</span>
                        <strong>Fixed Timings</strong>
                      </li>
                      <li>
                        <span>category</span>
                        <strong>{item.categoryId?.title}</strong>
                      </li>

                      <li>
                        <span>status</span>
                        <strong>{item.categoryId?.status}</strong>
                      </li>
                      {/* <li>
                      <span>sub category</span>
                      <strong>{item.subCategoryId?.title}</strong>
                    </li> */}
                      <li>
                        <span>Organized By</span>
                        <strong>Fixed Timings</strong>
                      </li>
                      <li>
                        <span>Dates</span>
                        <strong>Oct 21 - 31</strong>
                      </li>
                    </ul>
                  </div>
                  <ul className="listing-info listing-info-second col-12 col-md-5 col-lg-6 pe-0">
                    <li>
                      <span>Guests</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Forms Filled</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Documents</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Pre Screening</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Pre Integration</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Post Integration</span>
                      <strong>
                        <strong>10</strong>/<strong>20</strong>
                      </strong>
                    </li>
                    <li>
                      <span>Payment</span>
                      <strong>
                        <strong>$2,222</strong>/<strong>2,222</strong>
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Modal className="common-create-modal" show={show} onHide={handleClose}>
        <Container>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <div className="program-steps-card header-spacer">
                  <div className="form-wrap">
                    <Row>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Title</Form.Label>
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">
                            Description
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="description"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Category</Form.Label>

                          <Form.Control as="select">
                            {" "}
                            <option defaultValue>Please choose Category</option>
                            {category.map((item, i) => (
                              <option key={i} className="text-dark">
                                {item.title}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">
                            Sub Category
                          </Form.Label>
                          <Form.Control as="select">
                            <option defaultValue>
                              Please choose Sub Category
                            </option>
                            {subCategory.map((item, i) => (
                              <option key={i} className="text-dark">
                                {item.title}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Over View</Form.Label>
                          <Form.Control
                            type="text"
                            name="overView"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Address</Form.Label>
                          <Form.Control
                            type="text"
                            name="address"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">city</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Country</Form.Label>
                          <Form.Control
                            type="text"
                            name="country"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">
                            Country Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="countryCode"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">
                            Postal Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="postal_code"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">State</Form.Label>
                          <Form.Control
                            type="text"
                            name="state"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">
                            Street Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="street_number"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Image</Form.Label>
                          <Form.Control
                            type="file"
                            name="picURL"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">Start Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="startDate"
                            format="YYYY-MM-DD"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} xl={6}>
                        <Form.Group
                          className="form-group"
                          controlId="ControlInput1"
                        >
                          <Form.Label id="ControlInput1">End Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="endDate"
                            format="YYYY-MM-DD"
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div className="payment-footer">
                    <Row className="align-items-center w-100">
                      <Col md={12} lg={12} xl={6}>
                        <div className="action-btns mt-3 mt-xl-0 d-flex justify-content-xl-start">
                          <Button type="submit">Save</Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    </>
  );
}

export default ProgramListing;
