import { React, useEffect, useState } from "react";
import Settings from "../settings/settings";
import AddCategory from "../../components/category/addCategory";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../reduxSlice/categorySlice";
import CategoryList from "../../components/category/categoryList";

function Category() {
  const [state, setState] = useState("");
  const dispatch = useDispatch();
  const category = useSelector((state) => state.category.category);
  useEffect(() => {
    dispatch(getCategory(state));
  }, [dispatch, state]);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h3 className="page-header">Categories</h3>
              </div>
              <div>
                <AddCategory />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                </Form.Group>
                <i class="fa fa-search"></i>
              </div>
              <div className="filter-bar">
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <CategoryList category={category} />
          </div>
        </div>
      </Settings>
    </>
  );
}

export default Category;
