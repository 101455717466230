import Layout from "../../layout/layout";
import SettingSidebar from "./subComponent/settingSidebar";

function Settings({ children }) {
  return (
    <>
      <Layout>
        <div className="setting-layout">
          <div className="setting-sidebar ml-3">
            <SettingSidebar />
          </div>
          <div className="setting-main-content ">{children}</div>
        </div>
      </Layout>
    </>
  );
}
export default Settings;
