import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import OurTeamLayout from "./subComponent/ourTeamLayout";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTeamMember } from "../../reduxSlice/addTeamMemberSlice";
import Form from "react-bootstrap/Form";

function OurTeam() {
  const [searchState, setSearchState] = useState("");
  const [memberType, setMemberType] = useState("");
  const dispatch = useDispatch();
  const team = useSelector((state) => state);

  useEffect(() => {
    var data = { type: memberType, search: searchState };
    dispatch(getTeamMember(data));
  }, [memberType, searchState, dispatch]);

  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div>
                <h3 className="page-header">
                  {team.teamMeamber.teamMember.length} Members
                </h3>
              </div>
              <div>
                <Link
                  to="/ourTeam/addTeamMember"
                  className="custom-btn button-primary"
                >
                  {" "}
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout mb-3">
              <div className="d-flex gap-3">
                <div className="search-bar">
                  <Form.Group
                    className="form-group "
                    controlId="ProgramInputSearch"
                  >
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Search by name..."
                      value={searchState}
                      onChange={(e) => {
                        setSearchState(e.target.value);
                      }}
                    />
                  </Form.Group>
                  <i class="fa fa-search"></i>
                </div>
                <Form.Select
                  className="filter-dropdown"
                  aria-label="Default select example"
                  onChange={(e) => {
                    setMemberType(e.target.value);
                  }}
                >
                  <option value={"normal"}>All Members</option>
                  <option value="Creators Facilitators">
                    Creators Facilitators
                  </option>
                  <option value="Helpers">Helpers</option>
                  <option value="Maestros">Maestros</option>
                  <option value="Co-Organizer">Co-Organizer</option>
                  <option value="Group Members">Group Members</option>
                </Form.Select>
              </div>
              <div className="filter-bar">
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <OurTeamLayout team={team.teamMeamber} />
          </div>
        </div>
      </Layout>
    </>
  );
}
export default OurTeam;
