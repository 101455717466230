import { React, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Carousel from "react-bootstrap/Carousel";
import {
  getVenue,
  deleteVenue,
  updateVenue,
} from "../../../reduxSlice/venueSlice";
import { useDispatch } from "react-redux";
import SlideOver from "../../../components/slideOver/slideOver";
import Form from "react-bootstrap/Form";

import API from "../../../utils/baseUrls";
import { ApiEndPoint } from "../../../utils/apiEndPoints";
import InputText from "../../../components/input/inputText";
import InputDescription from "../../../components/input/inputDescription";

function VenueList(props) {
  const dispatch = useDispatch();
  const venues = props.venue.data;
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);
  const [venueId, setVenueId] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showError, setShowError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const deleteVenueItem = (id) => {
    dispatch(deleteVenue(id));
    setTimeout(() => {
      dispatch(getVenue());
    }, 200);
  };
  const handleVenueData = (item) => {
    setTitle(item.name);
    setLocation(item.location);
    setDescription(item.description);
    setAddress(item.address.address);
    const imageArr = item.images.map(({ type, _id, ...rest }) => ({ ...rest }));
    setImages(imageArr);
    setVenueId(item._id);
    handleShow();
  };
  const handleUpdateVenue = (e) => {
    e.preventDefault();
    const data = {
      id: venueId,
      title: title,
      description: description,
      location: location,
      address: { address },
      images: images,
    };
    if (!isSubmitDisabled) {
      dispatch(updateVenue(data));
      setTimeout(() => {
        handleClose();
      }, 200);
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };
  const uploadImage = (e, func) => {
    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    API.post(ApiEndPoint.uploadImage, formData)
      .then(function (response) {
        var data = [response.data];
        setImages([...images, data[0]]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setIsSubmitDisabled(
      !(title && location && description && images.length > 0)
    );
  }, [title, location, description, images]);

  return (
    <>
      <div>
        <div className="table-layout">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Image</th>
                <th>name</th>
                <th>Add On</th>
                <th>Map View</th>
                <th>Rooms</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {venues &&
                venues.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>
                      <Carousel
                        controls={false}
                        slide={false}
                        className="venue-list-image"
                      >
                        {item.images ? (
                          item.images.map((item) => (
                            <Carousel.Item interval={2000} key={item}>
                              <div>
                                <img src={item.original} alt="" />
                              </div>
                            </Carousel.Item>
                          ))
                        ) : (
                          <></>
                        )}
                      </Carousel>
                    </td>
                    <td>{item.name}</td>
                    <td>{item.addOn}</td>
                    <td>{item.location}</td>
                    <td>{item.rooms}</td>
                    <td width="300">{item.description}</td>
                    <td className="custom-dropdown text-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="">
                            <div className="drop">
                              <button
                                onClick={() => {
                                  handleVenueData(item);
                                }}
                                className="btn-icon"
                              >
                                <i className="fa fa-pencil"></i>Edit Venue
                              </button>
                            </div>
                            <SlideOver
                              show={show}
                              handleClose={handleClose}
                              header="Edit Articles"
                              content={
                                <div className="media-slide-over">
                                  <div className="image-section">
                                    <ul>
                                      {images ? (
                                        <>
                                          {images.map((item, index) => {
                                            return (
                                              <li>
                                                <img
                                                  src={item.original}
                                                  alt=""
                                                />
                                              </li>
                                            );
                                          })}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <li>
                                        <Form.Group
                                          className="slide-over-file-input"
                                          controlId="slideOverImage"
                                        >
                                          <Form.Label>
                                            <i
                                              className="fa fa-camera"
                                              aria-hidden="true"
                                            ></i>{" "}
                                            Image/Video
                                          </Form.Label>

                                          <Form.Control
                                            type="file"
                                            name="picURL"
                                            onChange={(e) => uploadImage(e)}
                                          />
                                        </Form.Group>
                                      </li>
                                    </ul>
                                  </div>
                                  <Form>
                                    <InputText
                                      label="Title"
                                      required={true}
                                      type="text"
                                      placeholder=""
                                      inputStyle="my-3"
                                      value={title}
                                      onChange={(e) => {
                                        setTitle(e.target.value);
                                      }}
                                    />
                                    <InputText
                                      label="Location"
                                      required={true}
                                      type="text"
                                      placeholder=""
                                      inputStyle="my-3"
                                      value={location}
                                      onChange={(e) => {
                                        setLocation(e.target.value);
                                      }}
                                    />
                                    <InputText
                                      label="Address"
                                      required={false}
                                      type="text"
                                      placeholder=""
                                      inputStyle="my-3"
                                      value={address}
                                      onChange={(e) => {
                                        setAddress(e.target.value);
                                      }}
                                    />
                                    <InputDescription
                                      label="Description"
                                      required={true}
                                      rows={4}
                                      placeholder=""
                                      inputStyle="my-3"
                                      value={description}
                                      onChange={(e) => {
                                        setDescription(e.target.value);
                                      }}
                                    />
                                    {showError ? (
                                      <small className="form-error">
                                        {showError}
                                      </small>
                                    ) : (
                                      <></>
                                    )}
                                    <button
                                      onClick={(e) =>
                                        handleUpdateVenue(e, "id")
                                      }
                                      className="custom-btn button-primary w-100 mt-3"
                                    >
                                      Submit
                                    </button>
                                  </Form>
                                </div>
                              }
                            />

                            <Dropdown.Divider />
                            <div className="drop delete pe-auto">
                              <button
                                onClick={() => {
                                  deleteVenueItem(item._id);
                                }}
                                className="btn-icon"
                              >
                                {" "}
                                <i className="fa fa-trash pr-1"></i>
                                Remove
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default VenueList;
