import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import subCategorySlice from "../reduxSlice/subCateogrySlice";
import categorySlice from "../reduxSlice/categorySlice";
import programSlice from "../reduxSlice/programSlice";
import addTeamMemberSlice from "../reduxSlice/addTeamMemberSlice";
import venueSlice from "../reduxSlice/venueSlice";
import articleSlice from "../reduxSlice/articleSlice";
import documentSlice from "../reduxSlice/documentSlice";
import usersSlice from "../reduxSlice/usersSlice";

export const store = configureStore({
  reducer: {
    category: categorySlice,
    subCategory: subCategorySlice,
    program: programSlice,
    teamMeamber: addTeamMemberSlice,
    venue: venueSlice,
    article: articleSlice,
    document: documentSlice,
    users: usersSlice,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
