import React, { useState } from "react";
import "./tabs.css";

function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <>
      <ul className="nav nav-tabs my-2" id="myTab" role="tablist">
        {tabs.map((tab) => (
          <li className="nav-item" role="presentation" key={tab.id}>
            <button
              className={`nav-link ${tab.id === activeTab ? "active" : ""}`}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {tabs.map((tab) => (
          <div
            className={`tab-pane mb-3 fade ${
              tab.id === activeTab ? "show active" : ""
            }`}
            id={tab.id}
            role="tabpanel"
            aria-labelledby={`${tab.id}-tab`}
            key={tab.id}
          >
            {tab.component}
          </div>
        ))}
      </div>
    </>
  );
}

export default Tabs;
