import React, { useEffect, useState } from "react";
import Layout from "../../layout/layout";
import Form from "react-bootstrap/Form";
import { getProgram } from "../../reduxSlice/programSlice";
import { Link } from "react-router-dom";
import InputText from "../../components/input/inputText";
import { useDispatch, useSelector } from "react-redux";
import Tabs from "../../components/tabs/tabs";
import ProgramListing from "../../components/program/programListing";

function Program() {
  const [state, setState] = useState("");
  const dispatch = useDispatch();
  const programs = useSelector((state) => state.program);
  console.log(programs);
  const tabs = [
    {
      id: "all-programs",
      label: `All Programs · ${programs.program.length}`,
      active: true,
      component: (
        <div>
          <ProgramListing programs={programs} />
        </div>
      ),
    },
    { id: "current-programs", label: "Current Programs · 0", active: false },
    {
      id: "upcoming-programs",
      label: "Upcoming in 2weeks · 0",
      active: false,
    },
    { id: "future-programs", label: "Future Programs · 0", active: false },
  ];
  useEffect(() => {
    dispatch(getProgram(state));
  }, [state, dispatch]);
  return (
    <>
      <Layout>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div>
                <h3 className="page-header">Programs</h3>
              </div>
              <div>
                <Link
                  to="/program/aboutProgram"
                  className="custom-btn button-primary"
                >
                  {" "}
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout mb-2">
              <div className="d-flex gap-3">
                <Form.Select
                  className="filter-dropdown"
                  aria-label="Default select example"
                >
                  <option>Program Type</option>
                  <option value="1">option 1</option>
                </Form.Select>
                <Form.Select
                  className="filter-dropdown"
                  aria-label="Default select example"
                >
                  <option>Category</option>
                  <option value="1">option 1</option>
                </Form.Select>
              </div>
              <div className="filter-bar">
                <div className="search-bar">
                  <InputText
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                  <i class="fa fa-search"></i>
                </div>
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Program;
