import { React, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import SlideOver from "../../components/slideOver/slideOver";

import Form from "react-bootstrap/Form";
function MediaList() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const media = [
    {
      id: 1,
      images: [
        "https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg?size=626&ext=jpg&ga=GA1.1.386372595.1698624000&semt=sph",
        "https://cdn.pixabay.com/photo/2014/02/27/16/10/flowers-276014_640.jpg",
      ],
      title: "Title",
      description: "Description",
    },
  ];
  return (
    <>
      <div>
        {media.map((item) => (
          <div key={item.id} className="category-list">
            <Carousel
              controls={false}
              slide={false}
              className="category-list-image"
            >
              {item.images.map((item) => (
                <Carousel.Item interval={2000}>
                  <div>
                    <img src={item} alt="" />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
            <div className="category-list-details">
              <div className="d-flex align-items-center justify-content-between">
                <h3 className="category-list-header">{item.title}</h3>
                <span className="media-title-time">1:37:25</span>
              </div>
              <p className="category-list-description">{item.description}</p>
              <div className=" d-flex gap-3">
                <button
                  onClick={() => setShow(true)}
                  className="category-list-buttons"
                >
                  <i class="fa fa-pencil"></i> Edit
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Edit Media"
                  content={<SlideOverContent />}
                />

                <button className="category-list-buttons">
                  <i class="fa fa-trash-o"></i> Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
  function SlideOverContent() {
    return (
      <>
        <div className="media-slide-over">
          <div className="image-section">
            <ul>
              <li>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Altja_j%C3%B5gi_Lahemaal.jpg/640px-Altja_j%C3%B5gi_Lahemaal.jpg"
                  alt=""
                />
              </li>
              <li>
                <Form.Group
                  className="slide-over-file-input"
                  controlId="slideOverImage"
                >
                  <Form.Label>
                    <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                    Image/Video
                  </Form.Label>

                  <Form.Control
                    type="file"
                    name="picURL"
                    placeholder="Type here..."
                  />
                </Form.Group>
              </li>
            </ul>
          </div>
          <Form>
            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={7} />
            </Form.Group>

            <button className="custom-btn button-primary w-100 mt-4">
              Submit
            </button>
          </Form>
        </div>
      </>
    );
  }
}

export default MediaList;
