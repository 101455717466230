import Layout from "../../../layout/layout";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApiEndPoint } from "../../../utils/apiEndPoints";
import API from "../../../utils/baseUrls";

import AddProgramCard from "../addProgramCard";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import InputText from "../../../components/input/inputText";
// import InputDescription from "../../../components/input/inputDescription";
import {
  // addDocument,
  getDocument,
  // getForm,
} from "../../../reduxSlice/documentSlice";
import { addProgramstep2 } from "../../../reduxSlice/programSlice";
// import { getTeamMember } from "../../../reduxSlice/addTeamMemberSlice";
import { Select } from "antd";
function InterviewsAndForms() {
  // const [selectedOption, setSelectedOption] = useState("");
  const [forms, setForms] = useState([]);
  const dispatch = useDispatch();
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [selectedDocumentData, setSelectedDocumentdata] = useState([]);
  const [selectedFormData, setSelectedFormData] = useState([]);
  const [selectedForm, setSelectedForm] = useState([]);
  const [preScreening, setPreScreening] = useState(false);
  const [preIntegration, setPreIntegration] = useState(false);
  const [postIntegration, setPostIntegration] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [selectedInterviewer, setSelectedInterviewer] = useState("");
  const [showNext, setShowNext] = useState(false);
  const [formValidation, setFormValidation] = useState(true);

  const [searchDocuments] = useState({
    type: "document",
    search: "",
  });
  const documents = useSelector((state) => state.document.documents);
  const team = useSelector((state) => state.teamMeamber.teamMember);

  // const handleRadioChange = (event) => {
  //   setSelectedOption(event.target.id);
  // };

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const buttons = [
    { id: 1, label: "Pre Screening", date: "24-30 June, 2023" },
    { id: 2, label: "Pre integration", date: "30-02 July, 2023" },
    { id: 3, label: "PROGRAM", date: "10-16 July, 2023" },
    { id: 4, label: "POST integration", date: "20-22 July, 2023" },
  ];
  const tabs = [
    {
      id: 1,
      name: "About Program",
      active: false,
      url: "/program/aboutProgram",
    },
    {
      id: 1,
      name: "Interviews & Forms",
      active: true,
      url: "/program/interviewsAndForms",
    },
    { id: 1, name: "Pricing", active: false, url: "/program/pricing" },
    {
      id: 1,
      name: "Registration & Email",
      active: false,
      url: "/program/registrationAndEmail",
    },
  ];
  useEffect(() => {
    dispatch(getDocument(searchDocuments));
    getForm();
  }, [dispatch, searchDocuments]);
  const getForm = () => {
    API.get(ApiEndPoint.addDocument + "?type=form").then((response) =>
      setForms(response.data)
    );
  };

  const onChangeForm = (item) => {
    console.log("selectedform", item);
    if (!selectedForm.includes(item)) {
      selectedForm.push(item);
    }
    const data = selectedForm.map((_id) => forms.find((el) => el._id === _id));
    setSelectedFormData(data);
  };

  const handleChangeDocument = (item) => {
    if (!selectedDocument.includes(item)) {
      selectedDocument.push(item);
    }
    const names = selectedDocument.map((id) =>
      documents.find((el) => el._id === id)
    );
    setSelectedDocumentdata(names);
  };

  const handleRemoveDocuments = (index) => {
    var selectedData = [...selectedDocument];
    selectedData.splice(index, 1);
    setSelectedDocument([...selectedData]);
    var data = [...selectedDocumentData];
    data.splice(index, 1);
    setSelectedDocumentdata([...data]);
  };

  const handleRemoveForms = (index) => {
    var selectedData = [...selectedForm];
    selectedData.splice(index, 1);
    setSelectedForm([...selectedData]);
    var data = [...selectedFormData];
    data.splice(index, 1);
    setSelectedFormData([...data]);
  };

  const handleInterviewerChange = (value) => {
    setSelectedInterviewer(value);
  };

  const saveStep2 = (e) => {
    // let programId = localStorage.getItem("programId");

    var data = {
      preScreening: {
        mandatory: preScreening,
        startDate: Date.parse(startDate),
        endDate: Date.parse(endDate),
      },
      preIntegration: { mandatory: preIntegration },
      postIntegration: { mandatory: postIntegration },
      documents: selectedForm,
      forms: selectedDocument,
    };

    if (!formValidation) {
      dispatch(addProgramstep2(data));
      setShowNext(true);
      alert("Values submitted successfully");
    } else {
      alert(`All fields are required`);
    }
  };

  useEffect(() => {
    setFormValidation(
      !(
        preScreening &&
        startDate &&
        endDate &&
        preIntegration &&
        postIntegration &&
        selectedForm &&
        selectedDocument
      )
    );
  }, [
    preScreening,
    startDate,
    endDate,
    preIntegration,
    postIntegration,
    selectedForm,
    selectedDocument,
  ]);

  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/program">
                  <i class="fa fa-chevron-left"></i> Programs ·{" "}
                  <span>Detail Page</span>
                </Link>
              </div>
              <div className="select-buttons my-3">
                <div className="button-container">
                  {buttons.map((button) => (
                    <div>
                      <button
                        key={button.id}
                        className={`button custom-btn ${
                          activeButton === button.id ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(button.id)}
                      >
                        {button.label}
                      </button>
                      <span className="button-date">{button.date}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="program-details-tabs">
                <ul>
                  {tabs.map((tab, index) => (
                    <li key={tab.id} className={tab.active ? "active" : ""}>
                      <Link
                        className={showNext && index > 3 ? "" : "pe-none"}
                        to={tab.url}
                      >
                        {tab.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <button
                  className="custom-btn button-primary"
                  onClick={() => {
                    saveStep2();
                  }}
                >
                  Save Changes
                </button>
              </div>

              {/* main content  */}

              <div className="d-flex flex-column gap-3 mt-3">
                <AddProgramCard
                  header="Pre Screening Interview"
                  body={
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          id="setPreScreening"
                          name="setPreScreening"
                          value={true}
                          checked={preScreening === true}
                          onChange={() => setPreScreening(true)}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          id="setPreScreening"
                          name="setPreScreening"
                          value={false}
                          checked={preScreening === false}
                          onChange={() => setPreScreening(false)}
                        />
                      </div>
                      <div className="d-flex gap-3 pre-screening-interview">
                        <div className="flex-fill">
                          <InputText
                            label="Start Date"
                            type="date"
                            name="startDate"
                            format="YYYY-MM-DD"
                            onChange={(e) => {
                              setStartDate(e.target.value);
                            }}
                            required={true}
                          />
                        </div>
                        <div className="flex-fill">
                          <InputText
                            label="End Date"
                            type="date"
                            name="endDate"
                            format="YYYY-MM-DD"
                            onChange={(e) => {
                              setEndDate(e.target.value);
                            }}
                            required={true}
                          />
                        </div>
                        <div className="interviewer">
                          <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Interviewer</Form.Label>

                            <Select
                              placeholder="Search to Select"
                              optionFilterProp="title"
                              options={team.map((i) => ({
                                label: i.fullName,
                                value: i._id,
                              }))}
                              value={selectedInterviewer}
                              onChange={handleInterviewerChange}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  }
                />
                <AddProgramCard
                  header="Pre Intigration Interview"
                  body={
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          id="preIntegration"
                          name="preIntegration"
                          value={true}
                          checked={preIntegration === true}
                          onChange={() => setPreIntegration(true)}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          id="preIntegration"
                          name="preIntegration"
                          value={false}
                          checked={preIntegration === false}
                          onChange={() => setPreIntegration(false)}
                        />
                      </div>
                    </div>
                  }
                />
                <AddProgramCard
                  header="Post Intigration Interview"
                  body={
                    <div className="d-flex flex-column gap-3">
                      <div className="d-flex gap-3">
                        <Form.Check
                          type="radio"
                          label="Yes"
                          id="postIntegration"
                          name="postIntegration"
                          value={true}
                          checked={postIntegration === true}
                          onChange={() => setPostIntegration(true)}
                        />
                        <Form.Check
                          type="radio"
                          label="No"
                          id="postIntegration"
                          name="postIntegration"
                          value={false}
                          checked={postIntegration === false}
                          onChange={() => setPostIntegration(false)}
                        />
                      </div>
                    </div>
                  }
                />
                <div className="d-flex gap-3">
                  <div className="w-100 w-lg-50">
                    <AddProgramCard
                      header="Documents Needed"
                      body={
                        <div>
                          <Select
                            options={documents.map((i) => ({
                              label: i.title,
                              value: i._id,
                            }))}
                            value={selectedDocument}
                            onChange={handleChangeDocument}
                          />
                          <div className="d-flex mt-3 flex-wrap gap-3">
                            {selectedDocumentData.map((item, index) => (
                              <div key={index} className="select-chips">
                                <button className="chips">
                                  <span>{item.title}</span>
                                  <i
                                    onClick={() => handleRemoveDocuments(index)}
                                    className="fa fa-close"
                                  ></i>
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                    />
                  </div>
                  <div className="w-100 w-lg-50">
                    <AddProgramCard
                      header="Health Form"
                      body={
                        <div>
                          <Select
                            options={forms.map((i) => ({
                              label: i.title,
                              value: i._id,
                            }))}
                            value={selectedForm}
                            onChange={onChangeForm}
                          />
                          <div className="d-flex mt-3 flex-wrap gap-3">
                            {selectedFormData.map((item, index) => (
                              <div className="select-chips">
                                <button className="chips">
                                  <span>{item.title}</span>
                                  <i
                                    onClick={() => handleRemoveForms(index)}
                                    className="fa fa-close"
                                  ></i>
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end gap-3">
                  <Link
                    to="/program/aboutProgram"
                    className="custom-btn button-secondary "
                  >
                    Back
                  </Link>
                  {showNext ? (
                    <Link
                      to="/program/pricing"
                      className="custom-btn button-primary "
                    >
                      Next
                    </Link>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default InterviewsAndForms;
