import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  articles: [],
  error: "",
};
export const getArticle = createAsyncThunk("user/getArticle", (data) => {
  if (data) {
    return API.get(ApiEndPoint.addArticle + "?search=" + data).then(
      (response) => response
    );
  } else if (!data) {
    return API.get(ApiEndPoint.addArticle).then((response) => response);
  }
});

const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    addArticle: (state, action) => {
      var data = action.payload;
      API.post(ApiEndPoint.addArticle, {
        title: data.title,
        description: data.description,
        images: data.images,
      });
    },
    deleteArticle: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.addArticle + "/" + id);
    },
    updateArticle: (state, action) => {
      var data = action.payload;
      API.put(ApiEndPoint.addArticle + "/" + data.id, {
        title: data.title,
        description: data.description,
        images: data.images,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getArticle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getArticle.fulfilled, (state, action) => {
      state.loading = false;
      state.articles = action.payload;
      state.error = "";
    });
    builder.addCase(getArticle.rejected, (state, action) => {
      state.loading = false;
      state.articles = [];
      state.error = action.error.message;
    });
  },
});

export default articleSlice.reducer;
export const { addArticle, deleteArticle, updateArticle } =
  articleSlice.actions;
