import Form from "react-bootstrap/Form";
import Layout from "../../layout/layout";
import { AddMember } from "../../schema/index";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import API from "../../utils/baseUrls";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { addUsers } from "../../reduxSlice/usersSlice";
function AddUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState();
  function uploadImage(e, func) {
    const formData = new FormData();
    formData.append("image", e);

    API.post(ApiEndPoint.uploadImage, formData)
      .then(function (response) {
        func("profilePic", response.data);
        setProfilePic(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function addCreator(e, func) {
    func("creator", [e.target.value]);
  }
  const initialValues = {
    creator: "",
    fullName: "",
    email: "",
    password: "",
    description: "",
    profilePic: "",
  };
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: AddMember,

    onSubmit: (values, action) => {
      var data = {
        creator: values.creator,
        fullName: values.fullName,
        email: values.email,
        password: values.password,
        description: values.description,
        profilePic: values.profilePic,
      };

      dispatch(addUsers(data));
      action.resetForm();
      setTimeout(() => {
        navigate(`/users`);
      }, 200);
    },
  });

  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/users">
                  <i class="fa fa-chevron-left"></i> Users ·{" "}
                  <span>Add new</span>
                </Link>
              </div>
              <h3 className="our-team-header">Add New User</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="add-team-layout d-flex gap-5">
                <div className="w-75 d-flex flex-column gap-3 add-team-card">
                  <div className="d-flex gap-3">
                    <Form.Group className=" w-50" controlId="firstName">
                      <Form.Label>Creator</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => addCreator(e, setFieldValue)}
                      >
                        <option defaultValue>Please choose team member</option>
                        <option value="Creators Facilitators">
                          Creators Facilitators
                        </option>
                        <option value="Helpers">Helpers</option>
                        <option value="Maestros">Maestros</option>
                        <option value="Co-Organizer">Co-Organizer</option>
                        <option value="Group Members">Group Members</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="w-50" controlId="firstName">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="fullName"
                        value={values.fullName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder=""
                      />
                      {errors.fullName && touched.fullName ? (
                        <label className="form-error">{errors.fullName}</label>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="d-flex gap-3 ">
                    <Form.Group className=" w-50" controlId="emailId">
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="text"
                        placeholder=""
                      />
                      {errors.email && touched.email ? (
                        <label className="form-error">{errors.email}</label>
                      ) : null}
                    </Form.Group>
                    <Form.Group className=" w-50" controlId="createPassword">
                      <Form.Label>Create Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder=""
                      />

                      {errors.password && touched.password ? (
                        <small className="form-error">{errors.password}</small>
                      ) : null}
                    </Form.Group>
                  </div>
                  <Form.Group className="" controlId="Description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows={3}
                    />
                    {errors.description && touched.description ? (
                      <label className="form-error">{errors.description}</label>
                    ) : null}
                  </Form.Group>
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      type="reset"
                      className="custom-btn button-secondary "
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="custom-btn button-primary "
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="w-25 upload-image-section">
                  <div className="img">
                    <div className="inner-img">
                      {profilePic ? (
                        <img src={profilePic.original} alt="" />
                      ) : (
                        <i className="fa fa-camera"></i>
                      )}
                    </div>
                  </div>
                  <Form.Group className="" controlId="uploadFile">
                    <Form.Label className="custom-btn button-secondary">
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      className="bg-white d-none"
                      type="file"
                      placeholder=""
                      name="profilePic"
                      onChange={(e) =>
                        uploadImage(e.target.files[0], setFieldValue)
                      }
                      onBlur={handleBlur}
                    />
                    {errors.profilePic && touched.profilePic ? (
                      <label className="form-error">{errors.profilePic}</label>
                    ) : null}
                  </Form.Group>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default AddUser;
