import { React, useState, useEffect } from "react";
import Layout from "../../layout/layout";
import VenueHeader from "./subComponent/venueHeader";
import VenueList from "./subComponent/venueList";
import Tabs from "../../components/tabs/tabs";
import SlideOver from "../../components/slideOver/slideOver";
import Form from "react-bootstrap/Form";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import API from "../../utils/baseUrls";
import { useDispatch, useSelector } from "react-redux";
import { addVenue, getVenue } from "../../reduxSlice/venueSlice";
import InputText from "../../components/input/inputText";
import InputDescription from "../../components/input/inputDescription";

function Venue() {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [images, setImages] = useState([]);

  const venue = useSelector((state) => state.venue.venues);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showError, setShowError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const tabs = [
    {
      id: "active",
      label: `Active · ${venue.data ? venue.data.length : 0}`,
      active: true,
      component: (
        <div>
          <VenueList venue={venue} />
        </div>
      ),
    },
    { id: "hidden", label: "Hidden · 0", active: false },
  ];

  function uploadImage(e) {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    API.post(ApiEndPoint.uploadImage, formData)
      .then(function (response) {
        var data = [response.data];
        setImages([...images, data[0]]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const addVenueData = (e) => {
    e.preventDefault();
    var data = {
      name: title,
      location: location,
      address: { address },
      images: images,
      description: description,
    };
    if (!isSubmitDisabled) {
      dispatch(addVenue(data));
      setTimeout(() => {
        dispatch(getVenue());
      }, 1000);
      handleClose();
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };
  useEffect(() => {
    dispatch(getVenue());
  }, [dispatch]);

  useEffect(() => {
    setIsSubmitDisabled(
      !(title && location && description && images.length > 0)
    );
  }, [title, location, description, images]);

  return (
    <>
      <Layout>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div>
                <h3 className="page-header">
                  Centres · {venue.data ? venue.data.length : 0}
                </h3>
              </div>
              <div>
                <button
                  onClick={() => handleShow()}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Venue"
                  content={
                    <div className="media-slide-over">
                      <div className="image-section">
                        <ul>
                          {images.map((item, index) => {
                            return (
                              <li>
                                <img src={item.original} alt="" />
                              </li>
                            );
                          })}

                          <li>
                            <Form.Group
                              className="slide-over-file-input"
                              controlId="slideOverImage"
                            >
                              <Form.Label>
                                <i
                                  className="fa fa-camera"
                                  aria-hidden="true"
                                ></i>{" "}
                                Image/Video
                              </Form.Label>

                              <Form.Control
                                type="file"
                                name="picURL"
                                placeholder="Type here..."
                                onChange={(e) => {
                                  uploadImage(e);
                                }}
                              />
                            </Form.Group>
                          </li>
                        </ul>
                      </div>
                      <Form>
                        <InputText
                          label="Title"
                          required={true}
                          type="text"
                          placeholder=""
                          inputStyle="my-3"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                        <InputText
                          label="Location"
                          required={true}
                          type="text"
                          placeholder=""
                          inputStyle="my-3"
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                        />
                        <InputText
                          label="Address"
                          required={false}
                          type="text"
                          placeholder=""
                          inputStyle="my-3"
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                        <InputDescription
                          label="Description"
                          required={true}
                          rows={4}
                          placeholder=""
                          inputStyle="my-3"
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                        />
                        {showError ? (
                          <small className="form-error">{showError}</small>
                        ) : (
                          <></>
                        )}

                        <button
                          className="custom-btn button-primary w-100 mt-3"
                          onClick={(e) => {
                            addVenueData(e);
                          }}
                        >
                          Submit
                        </button>
                      </Form>
                    </div>
                  }
                />
              </div>
            </div>
            <VenueHeader />
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Venue;
