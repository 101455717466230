import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  program: [],

  error: "",
};

export const getProgram = createAsyncThunk("user/getProgram", (id) => {
  if (id) {
    return API.get(
      ApiEndPoint.getPosts + "?search=" + id + "&skip=0&limit=10"
    ).then((response) => response.data.listing);
  } else if (!id) {
    return API.get(ApiEndPoint.getPosts + "?skip=0&limit=10").then(
      (response) => response.data.listing
    );
  }

  return API.get(ApiEndPoint.getPosts + "?skip=0&limit=10").then(
    (response) => response.data.listing
  );
});

const programSlice = createSlice({
  name: "program",
  initialState,
  reducers: {
    addProgram: (state, action) => {
      var data = action.payload;
      API.post(ApiEndPoint.addPosts, data)
        .then(function (response) {
          localStorage.setItem("programId", response.data._id);
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteProgram: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.getPosts + "/" + id);
    },

    addProgramstep2: (state, action) => {
      var data = action.payload;
      let programId = localStorage.getItem("programId");

      API.put(ApiEndPoint.addpostStep2 + "/" + programId, data)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addProgramstep3: (state, action) => {
      var data = action.payload;
      let programId = localStorage.getItem("programId");
      API.put(ApiEndPoint.addpostStep3 + "/" + programId, data)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addProgramstep4: (state, action) => {
      var data = action.payload;
      let programId = localStorage.getItem("programId");
      API.put(ApiEndPoint.addpostStep4 + "/" + programId, data)
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getProgram.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProgram.fulfilled, (state, action) => {
      state.loading = false;
      state.program = action.payload;
      state.error = "";
    });
    builder.addCase(getProgram.rejected, (state, action) => {
      state.loading = false;
      state.program = [];
      state.error = action.error.message;
    });
  },
});
export default programSlice.reducer;
export const {
  addProgram,
  addProgramstep2,
  addProgramstep3,
  addProgramstep4,
  deleteProgram,
} = programSlice.actions;
