function AddProgramCard({ header, body, className }) {
  return (
    <>
      <div className={`add-program-card ${className}`}>
        <div className={`card-header ${header ? "" : "d-none"}`}>{header}</div>
        <div className="card-body">{body}</div>
      </div>
    </>
  );
}

export default AddProgramCard;
