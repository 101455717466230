import Tabs from "../../components/tabs/tabs";
import Layout from "../../layout/layout";
import DashboardListing from "./dashboardListing";

function Dashboard() {
  const tabs = [
    {
      id: "current-program",
      label: "Current Programs (4)",
      active: true,
      component: (
        <div>
          <DashboardListing />
        </div>
      ),
    },
    {
      id: "upcoming",
      label: "Upcoming in 2 weeks (22)",
      active: false,
      component: <div>sdkjflsd</div>,
    },
    {
      id: "future-program",
      label: "Future Programs (22)",
      active: false,
      component: <div>sdkjflsd</div>,
    },
  ];
  const stats = [
    { value: { value1: "20", value2: "30" }, title: "Retreats Fully Booked" },
    { value: { value1: "198", value2: "230" }, title: "Spots Booked" },
    { value: { value1: "$22,812", value2: "" }, title: "Payment Received" },
    { value: { value1: "124", value2: "320" }, title: "Documents Completed" },
  ];
  return (
    <>
      <Layout>
        <div className="dashboard-layout my-3 d-flex flex-column gap-3">
          <div className="dashboard-stats">
            {stats.map((stat) => (
              <div key={stat} className="stats-card">
                <div className="stats-title">
                  <strong>{stat.value.value1}</strong>
                  {stat.value.value2 ? (
                    <>
                      <span>/{stat.value.value2}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="stats-desc">{stat.title}</div>
              </div>
            ))}
          </div>
          <div className="dashboard-chart">
            <div className="chart-header">Registration Volume</div>
            <div className="chart-body"></div>
          </div>
          <div className="dashboard-tabs">
            <Tabs tabs={tabs} />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
