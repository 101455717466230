import { React, useEffect, useState } from "react";
import Settings from "../settings/settings";
import Form from "react-bootstrap/Form";
import ArticleList from "./articleList";
import SlideOver from "../../components/slideOver/slideOver";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import API from "../../utils/baseUrls";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, getArticle } from "../../reduxSlice/articleSlice";
import InputText from "../../components/input/inputText";
import InputDescription from "../../components/input/inputDescription";
function Articles() {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);

  const articles = useSelector((state) => state.article.articles);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showError, setShowError] = useState("");
  const [search, setSearch] = useState("");

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  useEffect(() => {
    dispatch(getArticle());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getArticle(search));
  }, [search]);

  function uploadImage(e) {
    const formData = new FormData();
    formData.append("image", e.target.files[0]);

    API.post(ApiEndPoint.uploadImage, formData)
      .then(function (response) {
        var data = [response.data];
        setImages([...images, data[0]]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const handleArticleData = (e) => {
    e.preventDefault();
    var data = {
      title: title,
      images: images,
      description: description,
    };
    if (!isSubmitDisabled) {
      dispatch(addArticle(data));
      setTimeout(() => {
        dispatch(getArticle());
      }, 200);
      handleClose();
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(!(title && description && images.length > 0));
  }, [title, images, description]);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h3 className="page-header">Articles</h3>
              </div>
              <div>
                <button
                  onClick={() => setShow(true)}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus"></i> Add New
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Articles"
                  content={
                    <>
                      <Form>
                        <div className="media-slide-over">
                          <div className="image-section">
                            <ul>
                              {images.map((item, index) => {
                                return (
                                  <li key="item">
                                    <img src={item.original} alt="" />
                                  </li>
                                );
                              })}

                              <li>
                                <Form.Group
                                  className="slide-over-file-input"
                                  controlId="slideOverImage"
                                >
                                  <Form.Label>
                                    <i
                                      className="fa fa-camera"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Image/Video
                                  </Form.Label>

                                  <Form.Control
                                    type="file"
                                    name="picURL"
                                    onChange={(e) => {
                                      uploadImage(e);
                                    }}
                                  />
                                </Form.Group>
                              </li>
                            </ul>
                          </div>

                          <InputText
                            label="Title"
                            required={true}
                            inputStyle="my-3"
                            type="text"
                            placeholder=""
                            name="title"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                          <InputDescription
                            label="Description"
                            required={true}
                            inputStyle="my-3"
                            rows={7}
                            placeholder=""
                            name="Description"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                          {showError ? (
                            <small className="form-error">{showError}</small>
                          ) : (
                            <></>
                          )}
                          <button
                            onClick={(e) => {
                              handleArticleData(e);
                            }}
                            className="custom-btn button-primary w-100 mt-3"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </>
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </Form.Group>
                <i className="fa fa-search"></i>
              </div>
              <div className="filter-bar">
                <button className="button-secondary">
                  Sort By <i className="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <ArticleList articles={articles} />
          </div>
        </div>
      </Settings>
    </>
  );
}

export default Articles;
