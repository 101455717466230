import Form from "react-bootstrap/Form";
function PaymentTermsList() {
  const paymentMethods = [
    {
      image:
        "https://akm-img-a-in.tosshub.com/businesstoday/images/story/202306/ccc-sixteen_nine.jpg?size=1200:675",
      title: "Master/Visa Card",
    },
    {
      image:
        "https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2016/07/getting-paypal-credit-card.jpg",
      title: "Pay Pal",
    },
    {
      image:
        "https://gumlet.assettype.com/barandbench%2F2022-09%2F9e5cdb91-c2ba-4d2f-94d1-a0ad75f33515%2FSUPREME_COURT_OF_INDIA__WEB_PAGE_1600x900___Copy.jpg?format=auto",
      title: "Razorpay",
    },
    {
      image:
        "https://cdn.zeebiz.com/sites/default/files/styles/zeebiz_850x478/public/2019/05/03/85341-net-banking-pixabay.jpg?itok=Q4E6xurs",
      title: "Net Banking",
    },
    {
      image:
        "https://site.paytabs.com/wp-content/uploads/2021/05/Group-3975@2x.png",
      title: "Pay Tabs",
    },
  ];
  return (
    <>
      <ul className="payment-layout">
        {paymentMethods.map((item) => (
          <li key={item} className="payment-list">
            <img src={item.image} alt="" />
            <div className="list-details d-flex align-items-center justify-content-between">
              <strong>{item.title}</strong>
              <span>
                <Form.Check type="checkbox" id="yes" name="yes-no" />
              </span>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
}
export default PaymentTermsList;
