import { React } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
// import {
//   formatDate,
//   formatTimeDate,
//   todayDate,
// } from "../../helperFunction/formatter";
import { deleteUsers, getUsers } from "../../reduxSlice/usersSlice";
import { useDispatch } from "react-redux";
function UsersList({ users }) {
  const dispatch = useDispatch();
  const handleRemoveUser = (id) => {
    dispatch(deleteUsers(id));
    setTimeout(() => {
      dispatch(getUsers());
    }, 200);
  };

  return (
    <>
      <div className="table-layout">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Registered</th>
              <th>Program</th>
              <th>Stay Dates</th>
              <th>Arrival</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((i, index) => (
              <tr key={i}>
                <td>{index + 1}</td>
                <td>{i?.fullName}</td>
                {/* <td>{todayDate()}</td> */}
                <td>dummy data</td>
                <td>dummy data</td>
                <td>dummy data</td>
                <td>dummy data</td>
                {/* <td>{formatDate()}</td>
                <td>{formatTimeDate()}</td>
                <td>{formatDate()}</td> */}

                <td>
                  <div className="table-status">Approve for Booking</div>
                </td>
                <td className="custom-dropdown text-center">
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="">
                        <div className="drop">
                          <Link to={`/users/editUser/${i._id}`}>Edit</Link>{" "}
                        </div>
                        <Dropdown.Divider />
                        <div className="drop delete">
                          <button
                            onClick={() => handleRemoveUser(i._id)}
                            className="btn-icon"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default UsersList;
