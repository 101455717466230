import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  documents: [],
  countDocument: 0,
  error: "",
};
export const getDocument = createAsyncThunk("user/getDocument", (data) => {
  if (data.search) {
    return API.get(
      ApiEndPoint.addDocument + "?search=" + data.search + "&type=" + data.type
    ).then((response) => response.data);
  } else if (!data.search) {
    return API.get(ApiEndPoint.addDocument + "?type=" + data.type).then(
      (response) => response.data
    );
  }
});
// export const countDocument = createAsyncThunk("user/countDocument", (data) => {
//   if (data.search) {
//     return API.get(
//       ApiEndPoint.countDocument +
//         "?search=" +
//         data.search +
//         "&type=" +
//         data.type
//     ).then((response) => response.data);
//   } else if (!data.search) {
//     return API.get(ApiEndPoint.countDocument + "?type=" + data.type).then(
//       (response) => response.data
//     );
//   }
// });

const documentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    addDocument: (state, action) => {
      var data = action.payload;
      API.post(ApiEndPoint.addDocument, {
        title: data.title,
        description: data.description,
        type: data.type,
      });
    },
    deleteDocument: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.addDocument + "/" + id);
    },
    updateDocument: (state, action) => {
      var data = action.payload;
      API.put(ApiEndPoint.addDocument + "/" + data.id, {
        title: data.title,
        description: data.description,
      });
    },
    countDocument: (state, action) => {
      var data = action.payload;
      API.put(ApiEndPoint.countDocument + "?type=" + data.type);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDocument.fulfilled, (state, action) => {
      state.loading = false;
      state.documents = action.payload;
      state.error = "";
    });
    builder.addCase(getDocument.rejected, (state, action) => {
      state.loading = false;
      state.documents = [];
      state.error = action.error.message;
    });
    // builder.addCase(countDocument.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(countDocument.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.countDocument = action.payload;
    //   state.error = "";
    // });
    // builder.addCase(countDocument.rejected, (state, action) => {
    //   state.loading = false;
    //   state.countDocument = [];
    //   state.error = action.error.message;
    // });
  },
});

export default documentSlice.reducer;
export const { addDocument, deleteDocument, updateDocument } =
  documentSlice.actions;
