import { Link } from "react-router-dom";
import Layout from "../../layout/layout";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
function GuestListing() {
  return (
    <>
      <Layout>
        <div className="d-flex flex-column gap-3 py-3">
          <Link to="/program">
            <div className="forward-button">
              <i class="fa fa-chevron-left"></i> Programs ·{" "}
              <span>Guests Listing</span>
            </div>
          </Link>

          <div className="guest-header">
            <div className="details-section">
              <img
                className="header-image"
                src="https://img.freepik.com/premium-photo/glass-globe-with-white-lines-meridians-green-grass-green-planet-concept-nature-care-generative-ai_116317-27726.jpg"
                alt=""
              />
              <div className="header-details">
                <h3>10-Day Embodying True Nature Intensive</h3>
                <p>
                  <i className="fa fa-calendar"></i>October 21 - 31, 2023
                </p>
              </div>
            </div>
            <div>
              <button className="custom-btn button-primary">Edit</button>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between page-header-layout">
            <div className="search-bar">
              <Form.Group
                className="form-group "
                controlId="ProgramInputSearch"
              >
                <Form.Control
                  type="text"
                  name="title"
                  placeholder="Search by name..."
                />
              </Form.Group>
              <i class="fa fa-search"></i>
            </div>
            <div className="filter-bar">
              <button className="button-secondary">
                Filter <i class="fa fa-sliders"></i>
              </button>
              <button className="button-secondary">
                Sort By <i class="fa fa-sort"></i>
              </button>
            </div>
          </div>

          {/* Main Content  */}
          <div className="table-layout">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Registered</th>
                  <th>Stay Dates</th>
                  <th>Arrival</th>
                  <th>Payment</th>
                  <th>Documents</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {[1, 2, 3].map((i) => (
                  <tr key={i}>
                    <td>1</td>
                    <td>Andrew Fletcher</td>
                    <td>May 20, 2023</td>
                    <td>Oct 21-31,2023</td>
                    <td>Oct 19 · 02PM</td>
                    <td>$799/$1000</td>
                    <td>7/10</td>
                    <td>
                      <div className="table-status">Approve for Booking</div>
                    </td>
                    <td className="custom-dropdown text-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <div className="">
                            <div className="drop">
                              <Link to="/program/guestListing/editGuest">
                                Edit
                              </Link>{" "}
                            </div>
                            <Dropdown.Divider />
                            <div className="drop delete">Remove</div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default GuestListing;
