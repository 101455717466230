import axios from "axios";

const api = axios.create({
  baseURL: "https://heartofthejungle-api.techessentia.com/",
  responseType: "json",
  headers: {
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
  timeout: 20000,
  timeoutErrorMessage: "Server Timeout",
});

export default api;
