import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
function ProductList({ product }) {
  return (
    <>
      <tr>
        <td>{product.id}</td>
        <td>{product.name} </td>
        <td>
          <img className="venue-list-image" src={product.image} alt="" />
        </td>
        <td>{product.category} </td>
        <td>{product.quantity} </td>
        <td>{product.color} </td>
        <td>
          <div className="d-flex">
            {product.size.map((i, ind) => (
              <div key={i}>
                {i}
                {ind < product.size.length - 1 ? "," : ""}
              </div>
            ))}{" "}
          </div>
        </td>
        <td className="custom-dropdown text-center">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="">
                <div className="drop">
                  <Link to="/products/editProduct">Edit</Link>{" "}
                </div>
                <Dropdown.Divider />
                <div className="drop delete">Remove</div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  );
}
export default ProductList;
