import Dropdown from "react-bootstrap/Dropdown";
function InterviewerList() {
  return (
    <>
      <div className="interviewer-layout">
        {[1, 2, 3].map((i) => (
          <div key="i" className="interviewer-list">
            <div className="interviewer-img">
              <img
                src="https://img.freepik.com/premium-vector/young-smiling-man-avatar-man-with-brown-beard-mustache-hair-wearing-yellow-sweater-sweatshirt-3d-vector-people-character-illustration-cartoon-minimal-style_365941-860.jpg"
                alt=""
              />
              <h3 className="interviewer-title mt-2">Andrew Fletcher</h3>
              <p className="interviewer-email">fletcher.andrew@cmail.co</p>
            </div>
            <ul className="mt-3">
              {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((i) => (
                <li
                  key="i"
                  className="interviewer-list-details d-flex align-items-center justify-content-between "
                >
                  <strong>{i}</strong>
                  <div className="d-flex align-items-center gap-3">
                    <span>10:00AM - 02:30PM</span>
                    <span>
                      <i className="fa fa-pencil"></i>
                    </span>
                  </div>
                </li>
              ))}
            </ul>
            <div className="interviewer-dropdown">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="">
                    <div className="drop">Edit</div>
                    <Dropdown.Divider />
                    <div className="drop delete">Duplicate</div>
                    <Dropdown.Divider />
                    <div className="drop delete">Delete</div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
export default InterviewerList;
