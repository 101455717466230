import { React } from "react";
import API from "../../utils/baseUrls";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { registerSchema } from "../../schema/index";
import InputText from "../../components/input/inputText";
import LoginLayout from "../../layout/loginLayout";
import { Select } from "antd";
function Register() {
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");
  // const [password, setPassword] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState();
  // const [name, setName] = useState("");
  // const [type, setType] = useState();

  const initialValues = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    type: "",
    name: "",
  };
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema: registerSchema,

    onSubmit: (values, action) => {
      API.post(ApiEndPoint.register, {
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        name: values.name,
        type: values.type,
        phoneNumber: values.phoneNumber,
      })
        .then(function (response) {
          var token = response.data.access_token;
          if (!token) {
            navigate("/");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      // action.resetForm();
    },
  });

  return (
    <>
      <LoginLayout>
        {" "}
        <form onSubmit={handleSubmit} className="myform">
          <InputText
            type="email"
            name="email"
            placeholder="Email"
            value={values.email}
            onChange={handleChange}
            required={true}
            errors={errors.email && touched.email ? errors.email : null}
          />
          <InputText
            type="text"
            name="firstName"
            placeholder="First Name"
            value={values.firstName}
            onChange={handleChange}
            required={true}
            errors={
              errors.firstName && touched.firstName ? errors.lastName : null
            }
          />
          <InputText
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={values.lastName}
            onChange={handleChange}
            required={true}
            errors={
              errors.lastName && touched.lastName ? errors.lastName : null
            }
          />
          <InputText
            type="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onChange={handleChange}
            required={true}
            errors={
              errors.password && touched.password ? errors.password : null
            }
          />
          <InputText
            type="text"
            name="name"
            placeholder="Name"
            value={values.name}
            onChange={handleChange}
            required={true}
            errors={errors.name && touched.name ? errors.name : null}
          />
          <InputText
            type="text"
            name="phoneNumber"
            placeholder="Phone Number"
            value={values.phoneNumber}
            onChange={handleChange}
            required={true}
            errors={
              errors.phoneNumber && touched.phoneNumber
                ? errors.phoneNumber
                : null
            }
          />
          <div className="form-group">
            <Select
              placeholder="Select Type"
              optionFilterProp="title"
              name="type"
              options={[
                { label: "Super Admin", value: "SUPERADMIN" },
                { label: "Admin", value: "ADMIN" },
              ]}
              onChange={handleChange}
            />
          </div>
          {errors.phoneNumber && touched.phoneNumber ? (
            <small className="form-error">{errors.phoneNumber}</small>
          ) : null}

          <div className="form-group my-3 text-center">
            <button type="submit" className="btn btn-block gap-5 loginButton">
              Register
            </button>
          </div>
        </form>
      </LoginLayout>
    </>
  );
}

export default Register;
