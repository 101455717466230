import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  teamMember: [],

  error: "",
};

export const getTeamMember = createAsyncThunk("user/getTeamMember", (data) => {
  console.log("data forgetTeamMember", data);
  if (data.search) {
    return API.get(ApiEndPoint.addTeamMember + "?search=" + data.search).then(
      (response) => response.data
    );
  } else if (data.type && data.type != "normal") {
    return API.get(ApiEndPoint.addTeamMember + "?creator=" + data.type).then(
      (response) => response.data
    );
  } else {
    return API.get(ApiEndPoint.addTeamMember).then((response) => response.data);
  }
});

const addTeamMemberSlice = createSlice({
  name: "teammember",
  initialState,
  reducers: {
    addTeamMember: (state, action) => {
      var data = action.payload;
      console.log(data, "djalkjsdk");
      API.post(ApiEndPoint.addTeamMember, data)
        .then(function (response) {
          console.log(response, "redux");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteTeamMember: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.addTeamMember + "/" + id);
    },
    updateTeamMember: (state, action) => {
      var data = action.payload;
      var id = data.id;
      API.put(ApiEndPoint.addTeamMember + "/" + id, {
        // creator: data.creator,
        fullName: data.fullName,
        email: data.email,
        password: data.password,
        description: data.description,
        profilePic: data.profilePic,
        viewOnHome: data.viewOnHome,
        isBlock: data.isBlock,
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTeamMember.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTeamMember.fulfilled, (state, action) => {
      state.loading = false;
      state.teamMember = action.payload;
      state.error = "";
    });
    builder.addCase(getTeamMember.rejected, (state, action) => {
      state.loading = false;
      state.teamMember = [];
      state.error = action.error.message;
    });
  },
});
export default addTeamMemberSlice.reducer;

export const { addTeamMember, updateTeamMember, deleteTeamMember } =
  addTeamMemberSlice.actions;
