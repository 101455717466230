import { Link } from "react-router-dom";
import Layout from "../../layout/layout";
import ProductList from "./subComponent/productsList";
import Form from "react-bootstrap/Form";
function Products() {
  const products = [
    {
      id: 1,
      name: "Harem Pants w/Pockets Shipibo Icaro Pattern",
      image:
        "https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630",
      category: "Bordados shiphos",
      quantity: 24,
      color: "Blue",
      size: ["S", "M", "L", "XL"],
    },
  ];
  return (
    <>
      <Layout>
        <div className="add-wrap product-layout">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div>
                <h3 className="page-header">Products</h3>
              </div>
              <div>
                <Link
                  to="/products/addProduct"
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus"></i> Add New
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="d-flex align-items-center gap-3">
                <div className="search-bar">
                  <Form.Group
                    className="form-group "
                    controlId="ProgramInputSearch"
                  >
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Search by name..."
                    />
                  </Form.Group>
                  <i class="fa fa-search"></i>
                </div>
                <Form.Select
                  className="filter-dropdown"
                  aria-label="Default select example"
                >
                  <option>Category</option>
                  <option value="1">All Category</option>
                  <option value="2">Bordados shipibos</option>
                  <option value="2">Bordados shipibos</option>
                  <option value="3">Juan Carlos art</option>
                </Form.Select>
              </div>
              <div className="filter-bar">
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>

            {/* Main content  */}
            <div className="table-layout">
              <table className="w-100">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Image</th>
                    <th>Category</th>
                    <th>Quantity</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <ProductList key={product.id} product={product} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Products;
