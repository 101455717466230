import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  subCategory: [],
  error: "",
};
export const getSubCategory = createAsyncThunk(
  "user/getSubCategory",
  (data) => {
    if (data.search.length > 0) {
      return API.get(
        ApiEndPoint.getSubCateogory +
          "?categoryId=" +
          data.id +
          "&search=" +
          data.search
      ).then((response) => response.data);
    } else {
      return API.get(
        ApiEndPoint.getSubCateogory + "?categoryId=" + data.id
      ).then((response) => response.data);
    }
  }
);

const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {
    addSubCategory: (state, action) => {
      var data = action.payload;
      API.post(ApiEndPoint.addSubCateogory, {
        title: data.title,
        description: data.description,
        categoryId: data.params.id,
        images: data.images,
      });
    },
    deleteCategory: (state, action) => {
      var data = action.payload;

      API.delete(ApiEndPoint.deleteSubCateogory + "/" + data);
    },
    updateSubCategory: (state, action) => {
      var data = action.payload;

      API.put(ApiEndPoint.updateSubCateogory + "/" + data.catId, {
        title: data.title,
        description: data.description,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubCategory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.subCategory = action.payload;
      state.error = "";
    });
    builder.addCase(getSubCategory.rejected, (state, action) => {
      state.loading = false;
      state.subCategory = [];
      state.error = action.error.message;
    });
  },
});

export default subCategorySlice.reducer;
export const { addSubCategory, deleteCategory, updateSubCategory } =
  subCategorySlice.actions;
