import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  users: [],
  error: "",
};

export const getUsers = createAsyncThunk("user/getUsers", (data) => {
  console.log("data forgetTeamMember", data);
  if (data) {
    return API.get(ApiEndPoint.getUsers + "?search=" + data).then(
      (response) => response.data
    );
  } else {
    return API.get(ApiEndPoint.getUsers).then((response) => response.data);
  }
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    addUsers: (state, action) => {
      var data = action.payload;

      API.post(ApiEndPoint.getUsers, data)
        .then(function (response) {
          console.log(response, "redux");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteUsers: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.getUsers + "/" + id);
    },
    updateUsers: (state, action) => {
      var data = action.payload;
      var id = data.id;
      API.put(ApiEndPoint.getUsers + "/" + id, {
        fullName: data.fullName,
        email: data.email,
        password: data.password,
        description: data.description,
        profilePic: data.profilePic,
        viewOnHome: data.viewOnHome,
        isBlock: data.isBlock,
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = "";
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
      state.error = action.error.message;
    });
  },
});
export default usersSlice.reducer;

export const { addUsers, deleteUsers, updateUsers } = usersSlice.actions;
