import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoint } from "../utils/apiEndPoints";
import API from "../utils/baseUrls";

const initialState = {
  loading: false,
  venues: [],
  error: "",
};
export const getVenue = createAsyncThunk("user/getVenue", (data) => {
  if (data) {
    return API.get(ApiEndPoint.addVenue + "?search=" + data).then(
      (response) => response
    );
  } else if (!data) {
    return API.get(ApiEndPoint.addVenue).then((response) => response);
  }
});

const venueSlice = createSlice({
  name: "venue",
  initialState,
  reducers: {
    addVenue: (state, action) => {
      var data = action.payload;

      API.post(ApiEndPoint.addVenue, data)
        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteVenue: (state, action) => {
      var id = action.payload;
      API.delete(ApiEndPoint.addVenue + "/" + id);
    },
    updateVenue: (state, action) => {
      var data = action.payload;
      API.put(ApiEndPoint.addVenue + "/" + data.id, {
        name: data.title,
        description: data.description,
        location: data.location,
        address: data.address,
        images: data.images,
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVenue.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVenue.fulfilled, (state, action) => {
      state.loading = false;
      state.venues = action.payload;
      state.error = "";
    });
    builder.addCase(getVenue.rejected, (state, action) => {
      state.loading = false;
      state.venues = [];
      state.error = action.error.message;
    });
  },
});

export default venueSlice.reducer;
export const { addVenue, deleteVenue, updateVenue } = venueSlice.actions;
