import { React } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

function SettingSidebar() {
  const location = useLocation();
  return (
    <>
      <div className="setting-sidebar-content">
        <ul className="setting-sidebar-menu">
          <li>
            <NavLink to="/settings/category">Categories</NavLink>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/articles" ? "active" : ""
              }`}
              to="/settings/articles"
            >
              Articles
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/media" ? "active" : ""
              }`}
              to="/settings/media"
            >
              Media
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/interviewers" ? "active" : ""
              }`}
              to="/settings/interviewers"
            >
              Interviewers
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/documents" ||
                location.pathname === "/settings/forms"
                  ? "active"
                  : ""
              }`}
              to="/settings/documents"
            >
              Documents, Forms
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/emails" ? "active" : ""
              }`}
              to="/settings/emails"
            >
              Emails
            </Link>
          </li>
          <li>
            <Link
              className={`${
                location.pathname === "/settings/paymentTerms" ? "active" : ""
              }`}
              to="/settings/paymentTerms"
            >
              Payment Terms
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}
export default SettingSidebar;
