import Form from "react-bootstrap/Form";
import Layout from "../../../layout/layout";
import React, { useState } from "react";
import { Link } from "react-router-dom";
function AddProduct() {
  const [activeSizeButton, setActiveSizeButton] = useState(null);
  const [activeColorButton, setActiveColorButton] = useState(null);
  const handleSizeButtonClick = (buttonId) => {
    setActiveSizeButton(buttonId);
  };
  const handleColorButtonClick = (buttonId) => {
    setActiveColorButton(buttonId);
  };
  const sizeButtons = [
    { id: 1, label: "S" },
    { id: 2, label: "M" },
    { id: 3, label: "L" },
    { id: 4, label: "XL" },
  ];
  const colorButtons = [
    { id: 1, label: "Blue" },
    { id: 2, label: "Red" },
    { id: 3, label: "Green" },
    { id: 4, label: "Yellow" },
  ];
  const [images, setImages] = useState([
    {
      original:
        "https://heartofthejungle-api.techessentia.com/1698660979314client.png",
      thumbnail:
        "https://heartofthejungle-api.techessentia.com/1698660979314thumbnail_client.png",
    },
  ]);
  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/products" className="forward-button">
                  <i class="fa fa-chevron-left"></i> Products ·{" "}
                  <span>Add new</span>
                </Link>
              </div>
              <h3 className="our-team-header">Add New Product</h3>
            </div>
            <form>
              <div className="add-team-layout d-flex gap-3">
                <div className="w-75 d-flex flex-column gap-3 add-team-card">
                  <div className="d-flex gap-3">
                    <Form.Group className=" w-50" controlId="emailId">
                      <Form.Label>Category</Form.Label>
                      <Form.Control
                        name="category"
                        type="text"
                        placeholder=""
                      />
                    </Form.Group>

                    <Form.Group className="w-50" controlId="firstName">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="productName"
                        placeholder=""
                      />
                    </Form.Group>
                  </div>
                  <div className="d-flex gap-3 ">
                    <Form.Group className=" w-50" controlId="firstName">
                      <Form.Label>Price</Form.Label>
                      <Form.Select aria-label="Default select example">
                        <option defaultValue>Active</option>
                        <option>Active</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className=" w-50" controlId="sizeButtons">
                      <Form.Label>Size</Form.Label>
                      <div className="product-select-buttons">
                        <div className="button-container">
                          {sizeButtons.map((button) => (
                            <button
                              type="button"
                              key={button.id}
                              className={`button ${
                                activeSizeButton === button.id ? "active" : ""
                              }`}
                              onClick={() => handleSizeButtonClick(button.id)}
                            >
                              {button.label}
                            </button>
                          ))}
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                  <Form.Group className="w-100" controlId="sizeButtons">
                    <Form.Label>color</Form.Label>
                    <div className="product-select-buttons">
                      <div className="button-container">
                        {colorButtons.map((button) => (
                          <button
                            type="button"
                            key={button.id}
                            className={`button ${
                              activeColorButton === button.id ? "active" : ""
                            }`}
                            onClick={() => handleColorButtonClick(button.id)}
                          >
                            {button.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group className="" controlId="Description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" name="description" rows={3} />
                  </Form.Group>
                  <div className="d-flex justify-content-end gap-3">
                    <button
                      type="reset"
                      className="custom-btn button-secondary "
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="custom-btn button-primary "
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <div className="w-25 add-product-images add-program-feature">
                  <label htmlFor="" className="form-label">
                    Featured Images/Videos
                  </label>
                  <div className="images">
                    <ul>
                      {images.map((e, i) => {
                        return (
                          <li key={i}>
                            <img src={e.original} alt="" />
                          </li>
                        );
                      })}
                      <li>
                        <Form.Group
                          className="input-file"
                          controlId="uploadImage"
                        >
                          <Form.Label>
                            <i className="fa fa-camera"></i>
                          </Form.Label>

                          <Form.Control
                            type="file"
                            name="picURL"
                            //   onChange={(e) =>
                            //     uploadImage(
                            //       e.target.files[0],
                            //       setFieldValue
                            //     )
                            //   }
                            //   onBlur={handleBlur}
                            placeholder="Type here..."
                          />
                        </Form.Group>
                      </li>
                    </ul>
                    <p className="card-desc">
                      Click the image to edit or update
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default AddProduct;
