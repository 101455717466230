import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubCategory,
  deleteCategory,
  updateSubCategory,
} from "../../reduxSlice/subCateogrySlice";
import { useParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import SlideOver from "../slideOver/slideOver";
import InputDescription from "../input/inputDescription";
import InputText from "../input/inputText";
import UploadImage from "../../helperFunction/uploadImage";
function SubCategoryList() {
  const dispatch = useDispatch();
  const params = useParams();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [editData, setEditdata] = useState({});
  const [catId, setCatId] = useState("");
  const [showError, setShowError] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const subCategory = useSelector((state) => state.subCategory.subCategory);

  const uploadImage = async (e) => {
    const image = await UploadImage(e.target.files[0]);
    if (image) {
      setImages([...images, image]);
    } else {
      console.log("Image upload failed");
    }
  };

  const handleSlideover = (item) => {
    setEditdata(item);
    setShow(true);
  };

  function updateCat(e) {
    e.preventDefault();
    var formData = {
      title,
      description,
      catId,
    };
    if (!isSubmitDisabled) {
      dispatch(updateSubCategory(formData));
      setTimeout(() => {
        dispatch(getSubCategory(params.id));
      }, 200);
      handleClose();
    } else {
      setShowError("All fields are required");
    }
  }
  function deleteSubCategory(id) {
    dispatch(deleteCategory(id));
    setTimeout(() => {
      dispatch(getSubCategory(params.id));
    }, 200);
  }

  useEffect(() => {
    setTitle(editData.title);
    setDescription(editData.description);
  }, [editData]);

  useEffect(() => {
    dispatch(getSubCategory(params.id));
    setCatId(params.id);
  }, [params.id, dispatch]);

  useEffect(() => {
    setIsSubmitDisabled(!(title && description && images.length > 0));
  }, [title, description, images]);

  return (
    <>
      <div className="table-layout">
        <table className="w-100">
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Description</th>
              <th width="100">Action</th>
            </tr>
          </thead>
          <tbody>
            {subCategory ? (
              <>
                {subCategory.map((item, index) => (
                  <tr key={item._id}>
                    <td>{index + 1}</td>

                    <td>{item.title}</td>
                    <td style={{ width: "65%" }}>
                      <div className="line-clamp-2">{item.description}</div>
                    </td>
                    <td>
                      <div className="action align-items-center">
                        <button
                          className="category-list-buttons"
                          onClick={() => {
                            handleSlideover(item);
                          }}
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <SlideOver
                          show={show}
                          handleClose={handleClose}
                          header="Edit Sub Category"
                          content={
                            <div className="media-slide-over">
                              <div className="image-section">
                                <ul>
                                  {images ? (
                                    <>
                                      {images.map((item, index) => {
                                        return (
                                          <li>
                                            <img src={item.original} alt="" />
                                          </li>
                                        );
                                      })}
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  <li>
                                    <Form.Group
                                      className="slide-over-file-input"
                                      controlId="slideOverImage"
                                    >
                                      <Form.Label>
                                        <i
                                          className="fa fa-camera"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Image/Video
                                      </Form.Label>

                                      <Form.Control
                                        type="file"
                                        name="picURL"
                                        placeholder="Type here..."
                                        onChange={(e) => uploadImage(e)}
                                      />
                                    </Form.Group>
                                  </li>
                                </ul>
                              </div>
                              <Form>
                                <InputText
                                  label="Title"
                                  type="text"
                                  required={true}
                                  value={title}
                                  autoFocus
                                  onChange={(e) => setTitle(e.target.value)}
                                  inputStyle="my-3"
                                />
                                <InputDescription
                                  label="Description"
                                  required={true}
                                  value={description}
                                  rows={5}
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                />
                                {showError ? (
                                  <small className="form-error mt-2">
                                    {showError}
                                  </small>
                                ) : (
                                  <></>
                                )}

                                <button
                                  className="custom-btn button-primary w-100 mt-3"
                                  onClick={(e) => updateCat(e)}
                                >
                                  Submit
                                </button>
                              </Form>
                            </div>
                          }
                        />{" "}
                        <button
                          className="category-list-buttons"
                          onClick={() => deleteSubCategory(item._id)}
                        >
                          <i class="fa fa-trash-o"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SubCategoryList;
