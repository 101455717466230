import { React } from "react";
import sidebarLogo from "../../assets/images/sidebar-logo.svg";
import { Link, NavLink, useLocation } from "react-router-dom";

function Sidebar() {
  const location = useLocation();
  return (
    <>
      <div className="sidebar-content">
        <div className="sidebar-logo text-center">
          <img src={sidebarLogo} alt="logo" />
        </div>
        <ul className="sidebar-menu">
          <li>
            <Link
              className={`${
                location.pathname === "/dashboard" ? "active" : ""
              }`}
              to="/dashboard"
            >
              Dashboard
            </Link>
          </li>

          <li>
            <NavLink to="/program">Programs</NavLink>
          </li>
          <li>
            <Link
              className={`${location.pathname === "/venue" ? "active" : ""}`}
              to="/venue"
            >
              Venues
            </Link>
          </li>
          <li>
            <NavLink to="/users">Users</NavLink>
          </li>

          <li>
            <Link
              className={`${location.pathname === "/products" ? "active" : ""}`}
              to="/products"
            >
              Products
            </Link>
          </li>
          <li>
            <NavLink to="/ourTeam">Our Teams</NavLink>
          </li>

          <li>
            <NavLink to="/settings">Settings</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
