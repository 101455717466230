import { Link } from "react-router-dom";
import Layout from "../../layout/layout";
import Form from "react-bootstrap/Form";
import GuestCard from "./subComponent/guestCard";
function EditGuest() {
  return (
    <>
      <Layout>
        <div className="d-flex flex-column gap-3 py-3">
          <Link to="/program/guestListing">
            <div className="forward-button">
              <i class="fa fa-chevron-left"></i> Programs ·{" "}
              <span>Guests Listing</span>
            </div>
          </Link>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <h3 className="page-header">Andrew Fletcher</h3>
            </div>
            <div>
              <button className="custom-btn button-primary">
                Save Changes
              </button>
            </div>
          </div>
          <div className="guest-layout d-flex gap-3">
            <div
              style={{ width: "60%" }}
              className="d-flex flex-column gap-3 flex-shrink-0"
            >
              <GuestCard
                header="Registration Details"
                body={
                  <div className="registration-details px-3">
                    <div className="text-label">ID</div>
                    <div className="text-mute">#123</div>
                    <div className="text-label">program</div>
                    <div className="text-mute">
                      10-Day Embodying True Nature Intensive
                    </div>
                    <div className="text-label">Stay Dates</div>
                    <div className="text-mute d-flex align-items-center justify-content-between">
                      <span>May 24 - 30, 2023 (6 nights)</span>{" "}
                      <i className="fa fa-pencil"></i>
                    </div>
                    <div className="text-label">Status</div>
                    <div className="text-mute">
                      <Form.Select
                        className="w-50"
                        aria-label="Default select example"
                      >
                        <option defaultValue>Pre Screening</option>
                        <option value="Helpers">Helpers</option>
                        <option value="Maestros">Maestros</option>
                      </Form.Select>
                    </div>
                    <div className="text-label">Submitted</div>
                    <div className="text-mute">May 20, 2023 · 9:12AM</div>
                    <div className="text-label">Group</div>
                    <div className="text-mute">
                      {[1, 2, 3].map((i) => (
                        <div
                          key={i}
                          className="d-flex align-items-center justify-content-between"
                        >
                          <span>Kayle Jameson</span>
                          <i className="fa fa-trash"></i>
                        </div>
                      ))}
                      <button className="plain-button mt-2">
                        <i className="fa fa-plus"></i> Add More
                      </button>
                    </div>
                  </div>
                }
              />

              <GuestCard
                header="Venue & Room"
                body={
                  <div className="registration-details px-3">
                    <div className="text-label">Venue</div>
                    <div className="text-mute d-flex align-items-center justify-content-between">
                      <span>Sacred Valley, Peru</span>{" "}
                      <i className="fa fa-pencil"></i>
                    </div>
                    <div className="text-label">Room</div>
                    <div className="text-mute d-flex align-items-center justify-content-between">
                      <span>A 101 - Queen Bed</span>{" "}
                      <i className="fa fa-pencil"></i>
                    </div>
                  </div>
                }
              />
              <GuestCard
                header="Documents & Forms"
                cardBodyCss="p-0 pb-3"
                body={
                  <div className="documents-table-layout">
                    <table className="guest-table-layout">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Expiry</th>
                          <th>Submit On</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="https://img.freepik.com/premium-photo/glass-globe-with-white-lines-meridians-green-grass-green-planet-concept-nature-care-generative-ai_116317-27726.jpg"
                              alt=""
                            />
                          </td>
                          <td>Passport</td>
                          <td>Document</td>
                          <td>Jan24, 2034</td>
                          <td>June 10, 2023</td>
                          <td>
                            <i className="fa fa-pencil"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
              />
              <GuestCard
                header="Registration Transactions"
                cardBodyCss="p-0 pb-3"
                body={
                  <div className="registration-transaction-table-layout">
                    <table className="guest-table-layout">
                      <thead>
                        <tr>
                          <th>Program</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Tax</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {[1, 2, 3].map((i) => (
                          <tr key={i}>
                            <td width="260">10-Day Embodying True...</td>
                            <td>June 2, 2023</td>
                            <td>Pending</td>
                            <td>8%</td>
                            <td>$600.00</td>
                            <td>
                              <i className="fa fa-pencil"></i>
                            </td>
                          </tr>
                        ))}
                        <tr className="subtotal-row">
                          <td colspan="2"></td>
                          <td colspan="2" className="total-label">
                            Sub Total
                          </td>
                          <td colspan="2" className="total-value">
                            $2400.00
                          </td>
                        </tr>
                        <tr className="subtotal-row">
                          <td colspan="2"></td>
                          <td colspan="2" className="total-label">
                            Total Paid
                          </td>
                          <td colspan="2" className="total-value">
                            $0.00
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"></td>
                          <td colspan="2" className="total-label">
                            Balance Due
                          </td>
                          <td colspan="2" className="total-value">
                            $2400.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
              />
              <GuestCard
                header="Sent Mails"
                cardBodyCss="p-0 pb-3"
                body={
                  <div className="documents-table-layout">
                    <table className="guest-table-layout">
                      <thead>
                        <tr>
                          <th>date</th>
                          <th>Subject</th>
                          <th>Receipt</th>
                          <th>Context</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr></tr>
                        <tr>
                          <td colSpan="5" className="text-center">
                            <button className="plain-button">
                              <i className="fa fa-plus"></i> Send mail to Andrew
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
              />
            </div>
            <div className="flex-grow-1 d-flex flex-column gap-3">
              <GuestCard
                header="Person Field"
                body={
                  <div className="registration-details align-items-center">
                    <div className="text-label">First Name</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="first-name" />
                    </div>
                    <div className="text-label">Last Name</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="last-name" />
                    </div>
                    <div className="text-label">Email</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="email" />
                    </div>
                    <div className="text-label">Phone</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="phone" />
                    </div>
                    <div className="w-100 d-flex">
                      <div className="text-label">Address</div>
                      <div className="text-mute d-flex flex-column gap-2">
                        <Form.Control
                          type="text"
                          name="address"
                          placeholder="Address"
                        />
                        <Form.Control
                          type="text"
                          name="city"
                          placeholder="City"
                        />
                        <Form.Control
                          type="text"
                          name="state"
                          placeholder="State"
                        />
                        <Form.Control
                          type="text"
                          name="zip-postal"
                          placeholder="Zip/Postal"
                        />
                        <Form.Select aria-label="Default select example">
                          <option defaultValue>Country</option>
                          <option value="Helpers">India</option>
                          <option value="Maestros">USA</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="text-label">Gender</div>
                    <div className="text-mute">
                      <Form.Select aria-label="Default select example">
                        <option defaultValue>Male</option>
                        <option value="Helpers">Female</option>
                        <option value="Maestros">Other</option>
                      </Form.Select>
                    </div>
                    <div className="text-label">Birthday</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="bDay" />
                    </div>
                    <div className="text-label">Meal Type</div>
                    <div className="text-mute">
                      <Form.Select aria-label="Default select example">
                        <option defaultValue>Vegan</option>
                        <option value="Helpers">Non Vegan</option>
                        <option value="Maestros">Other</option>
                      </Form.Select>
                    </div>
                    <div className="text-label">Emergency User Name</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="emergency-user-name" />
                    </div>
                    <div className="text-label">Emergency Phone</div>
                    <div className="text-mute">
                      <Form.Control type="text" name="emergency-phone" />
                    </div>
                  </div>
                }
              />
              <GuestCard
                header="Previous Booked Programs "
                body={
                  <div className="booked-program d-flex flex-column gap-3">
                    {[1, 2].map((i) => (
                      <div key={i} className="booked-program-list">
                        <div className="main-content d-flex align-items-center gap-3">
                          <img
                            src="https://images.ctfassets.net/hrltx12pl8hq/28ECAQiPJZ78hxatLTa7Ts/2f695d869736ae3b0de3e56ceaca3958/free-nature-images.jpg?fit=fill&w=1200&h=630"
                            alt=""
                          />
                          <div>
                            <h3>10-Day Embodying True Nature Intensive</h3>
                            <p>
                              <i className="fa fa-calendar"></i>October 21 - 31,
                              2023
                            </p>
                          </div>
                        </div>
                        <div className="booked-program-footer d-flex align-items-center justify-content-between mt-2">
                          <span className="d-flex align-items-center gap-1">
                            <i class="fa fa-map-marker"></i>Paititi Institute,
                            Peru
                          </span>
                          <span>
                            1 Person <strong>$1,230</strong>{" "}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default EditGuest;
