import * as Yup from "yup";

export const BookingPlanSchema = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  type: Yup.string().required("Required"),
  numberOfDays: Yup.string().required("Required"),

  teamMembers: Yup.array()
    .of(
      Yup.object().shape({
        original: Yup.string().required("Required"), // these constraints take precedence
        thumbnail: Yup.string().required("Required"), // these constraints take precedence
      })
    )
    .required("Required"),
  picURL: Yup.array()
    .of(
      Yup.object().shape({
        original: Yup.string().required("Required"), // these constraints take precedence
        thumbnail: Yup.string().required("Required"), // these constraints take precedence
      })
    )
    .required("Required"),
  startDate: Yup.date().nullable().required("Required"),
  endDate: Yup.date().nullable().required("Required"),
  categoryId: Yup.string().required("Required"),
  subCategoryId: Yup.string().required("Required"),
});
export const loginSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string().required("Required"),
});
export const registerSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string().required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  phoneNumber: Yup.number().required("Required"),
  type: Yup.string().required("Required"),
});
export const AddMember = Yup.object({
  creator: Yup.array().required("Required"),
  fullName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Required")
    .min(8, "Pasword must be 8 or more characters"),
  description: Yup.string().required("Required"),
  profilePic: Yup.object().required("Required"),
});
