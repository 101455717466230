import { React, useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import SlideOver from "../../components/slideOver/slideOver";
import Form from "react-bootstrap/Form";
import {
  deleteArticle,
  getArticle,
  updateArticle,
} from "../../reduxSlice/articleSlice";
import { useDispatch } from "react-redux";
import { ApiEndPoint } from "../../utils/apiEndPoints";
import API from "../../utils/baseUrls";
import InputDescription from "../../components/input/inputDescription";
import InputText from "../../components/input/inputText";

function ArticleList(props) {
  const articles = props.articles.data;
  const dispatch = useDispatch();
  const [editData, setEditdata] = useState({});
  const [articleId, setArticleId] = useState("");
  const [showError, setShowError] = useState("");

  const handleDeleteArticle = (id) => {
    dispatch(deleteArticle(id));
    setTimeout(() => {
      dispatch(getArticle());
    }, 1000);
  };
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const handleSlideover = (value) => {
    setEditdata(value);
    setShow(true);
  };

  useEffect(() => {
    setTitle(editData.title);
    setDescription(editData.description);
    setImages(editData.images);
    setArticleId(editData._id);
  }, [editData]);

  const uploadImage = (e, func) => {
    const formData = new FormData();

    formData.append("image", e.target.files[0]);

    API.post(ApiEndPoint.uploadImage, formData)
      .then(function (response) {
        var data = [response.data];
        setImages([...images, data[0]]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleUpdateArticle = (e) => {
    e.preventDefault();
    if (!isSubmitDisabled) {
      const result = images.map(({ type, _id, ...rest }) => ({ ...rest }));
      const data = {
        id: articleId,
        title: title,
        description: description,
        images: result,
      };
      dispatch(updateArticle(data));
      setTimeout(() => {
        dispatch(getArticle());
        handleClose();
      }, 200);
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };

  useEffect(() => {
    setIsSubmitDisabled(!(title && description && images.length > 0));
  }, [title, description, images]);

  return (
    <>
      <div>
        {articles ? (
          articles.map((item) => (
            <div key={item._id} className="category-list">
              <Carousel
                controls={false}
                slide={false}
                className="category-list-image"
              >
                {item.images.map((item) => (
                  <Carousel.Item interval={2000}>
                    <div>
                      <img src={item.original} alt="" />
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="category-list-details">
                <h3 className="category-list-header">{item.title}</h3>
                <p className="category-list-description line-clamp-2">
                  {item.description}
                </p>
                <div className=" d-flex gap-3">
                  <button
                    className="category-list-buttons"
                    onClick={() => {
                      handleSlideover(item);
                    }}
                  >
                    <i class="fa fa-pencil"></i> Edit
                  </button>
                  <SlideOver
                    show={show}
                    handleClose={handleClose}
                    header="Edit Articles"
                    content={
                      <>
                        <div className="media-slide-over">
                          <Form onSubmit={(e) => handleUpdateArticle(e)}>
                            <div className="image-section">
                              <ul>
                                {images ? (
                                  <>
                                    {images.map((item, index) => {
                                      return (
                                        <li>
                                          <img src={item.original} alt="" />
                                        </li>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <></>
                                )}
                                <li>
                                  <Form.Group
                                    className="slide-over-file-input"
                                    controlId="slideOverImage"
                                  >
                                    <Form.Label>
                                      <i
                                        className="fa fa-camera"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Image/Video
                                    </Form.Label>

                                    <Form.Control
                                      type="file"
                                      name="picURL"
                                      placeholder="Type here..."
                                      onChange={(e) => uploadImage(e)}
                                    />
                                  </Form.Group>
                                </li>
                              </ul>
                            </div>

                            <InputText
                              label="Title"
                              required={true}
                              inputStyle="my-3"
                              type="text"
                              placeholder=""
                              name="title"
                              value={title}
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                            <InputDescription
                              label="Description"
                              required={true}
                              inputStyle="my-3"
                              rows={7}
                              placeholder=""
                              name="Description"
                              value={description}
                              onChange={(e) => {
                                setDescription(e.target.value);
                              }}
                            />
                            {showError ? (
                              <small className="form-error">{showError}</small>
                            ) : (
                              <></>
                            )}
                            <button
                              type="submit"
                              className="custom-btn button-primary w-100 mt-3"
                            >
                              Submit
                            </button>
                          </Form>
                        </div>
                      </>
                    }
                  />

                  <button
                    className="category-list-buttons"
                    onClick={() => handleDeleteArticle(item._id)}
                  >
                    <i class="fa fa-trash-o"></i> Delete
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ArticleList;
