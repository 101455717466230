import { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteTeamMember,
  getTeamMember,
} from "../../../reduxSlice/addTeamMemberSlice";
import { useDispatch, useSelector } from "react-redux";

function TeamMemberCard() {
  const teamMemebers = useSelector((state) => state.teamMeamber.teamMember);

  const [viewOnHome, setViewOnHome] = useState(true);
  const [isBlock, setIsBlock] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToEdit = (id) => {
    console.log(id);
    navigate(`/ourTeam/editTeamMember/${id}`);
  };

  const handleRemoveTeamMember = (id) => {
    dispatch(deleteTeamMember(id));
    setTimeout(() => {
      dispatch(getTeamMember());
    }, 200);
  };

  useEffect(() => {
    dispatch(getTeamMember());
  }, [dispatch]);

  return (
    <>
      {teamMemebers.map((member) => (
        <li>
          <div className="member-card">
            <div className="d-flex align-items-center justify-content-between card-header">
              <div>
                {viewOnHome ? (
                  <span>
                    <i class="fa fa-certificate"></i>
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <span>
                {" "}
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="">
                      <div className="drop">
                        <button
                          className="btn-icon"
                          onClick={() => {
                            goToEdit(member._id);
                          }}
                        >
                          Edit Member
                        </button>
                      </div>
                      <Dropdown.Divider />
                      <div
                        className="drop delete"
                        onClick={() => {
                          setViewOnHome(!viewOnHome);
                        }}
                      >
                        Show on Home Page
                      </div>
                      <Dropdown.Divider />
                      <div
                        className="drop delete"
                        onClick={() => {
                          setIsBlock(!isBlock);
                        }}
                      >
                        Block
                      </div>
                      <Dropdown.Divider />
                      <div className="drop delete">
                        <button
                          className="btn-icon"
                          onClick={() => handleRemoveTeamMember(member._id)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </span>
            </div>
            <img
              className="card-image"
              src={member.profilePic ? member.profilePic.original : ""}
              alt=""
            />
            <h3 className="card-title">{member.fullName}</h3>
            <p className="card-email">{member.email}</p>
            <p className="card-divider"></p>
            <span className="card-role">CREATOR · {member.creator[0]}</span>
          </div>
        </li>
      ))}
    </>
  );
}
export default TeamMemberCard;
