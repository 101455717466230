import { React, useState } from "react";
import Settings from "../settings/settings";

import Form from "react-bootstrap/Form";
import SlideOver from "../../components/slideOver/slideOver";
function Emails() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h3 className="page-header">Emails(33)</h3>
              </div>
              <div>
                <button
                  onClick={() => setShow(true)}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Emails"
                  content={<SlideOverContent />}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    //   value={state}
                    //   onChange={(e) => setState(e.target.value)}
                  />
                </Form.Group>
                <i class="fa fa-search"></i>
              </div>
            </div>
            <div className="">
              <div className="table-layout mt-2">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>When</th>
                      <th>Message</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3].map((i) => (
                      <tr key={i}>
                        <td>1</td>
                        <td>Registration Email</td>
                        <td>June 05,2023 · 10:00AM</td>
                        <td>Harem Pants w/Pockets Shipibo Icaro Pattern</td>
                        <td>
                          <div className="action">
                            <i className="fa fa-pencil"></i>{" "}
                            <i className="fa fa-trash"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Settings>
    </>
  );
  function SlideOverContent() {
    return (
      <>
        <div className="media-slide-over">
          <Form>
            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={7} />
            </Form.Group>

            <button className="custom-btn button-primary w-100 mt-4">
              Submit
            </button>
          </Form>
        </div>
      </>
    );
  }
}

export default Emails;
