function GuestCard({ header, body, cardBodyCss }) {
  return (
    <>
      <div className="guest-card-layout">
        <div className="card-header">{header}</div>
        <div className={`card-body ${cardBodyCss}`}>{body}</div>
      </div>
    </>
  );
}
export default GuestCard;
