import Form from "react-bootstrap/Form";
import Layout from "../../layout/layout";
import { useDispatch, useSelector } from "react-redux";

import {
  getTeamMember,
  updateTeamMember,
} from "../../reduxSlice/addTeamMemberSlice";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import { Select } from "antd";
import InputText from "../../components/input/inputText";
import UploadImage from "../../helperFunction/uploadImage";
function EditTeamMember() {
  const dispatch = useDispatch();
  const params = useParams();

  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [creator, setCreator] = useState([]);

  const teamMemebers = useSelector((state) => state.teamMeamber.teamMember);
  const [member, setMember] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    var member = teamMemebers.filter((i) => i._id === params.id);
    setMember(member[0]);
  }, params);

  const handleCreatorChange = (newValue) => {
    var selectedCreator = [...creator];
    selectedCreator = selectedCreator.map((i) => {
      if (i === newValue) {
        return null;
      } else {
        return i;
      }
    });
    selectedCreator = selectedCreator.filter((i) => i !== null);
    setCreator([...selectedCreator]);
  };

  const handleUploadImage = async (e) => {
    const image = await UploadImage(e.target.files[0]);
    if (image) {
      setProfilePic(image);
    } else {
      console.error("Image upload failed");
    }
  };
  const handleUpdateSubmit = () => {
    var data = {
      id: params.id,
      creator: creator,
      fullName: fullname,
      email: email,
      password: password,
      description: description,
      profilePic: profilePic,
    };

    dispatch(updateTeamMember(data));
    setTimeout(() => {
      navigate(`/ourTeam`);
    }, 200);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      dispatch(getTeamMember());
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dispatch]);

  useEffect(() => {
    setFullName(member?.fullName);
    setPassword(member?.password);
    setEmail(member?.email);
    setDescription(member?.description);
    setProfilePic(member?.profilePic);
    setCreator(member?.creator);
  }, [member]);

  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/ourTeam">
                  <i class="fa fa-chevron-left"></i> Our Teams ·{" "}
                  <span>Edit</span>
                </Link>
              </div>
              <h3 className="our-team-header">Edit Team Member</h3>
            </div>
            {/* <form onSubmit={updateTeamMember}> */}
            <div className="add-team-layout d-flex gap-5">
              <div className="w-75 d-flex flex-column gap-3 add-team-card">
                <div className="d-flex gap-3">
                  <Form.Group className=" w-50" controlId="firstName">
                    <Form.Label>Creator</Form.Label>
                    <Select
                      placeholder="Search to Select"
                      optionFilterProp="title"
                      options={[
                        { label: "Helpers", value: "Helpers" },
                        { label: "Maestros", value: "Maestros" },
                        { label: "Co-Organizer", value: "Co-Organizer" },
                        { label: "Group Members", value: "Group Members" },
                      ]}
                      value={creator}
                      onChange={handleCreatorChange}
                    />
                  </Form.Group>
                  <InputText
                    label="Full Name"
                    type="text"
                    value={fullname}
                    inputStyle="w-50"
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-3 ">
                  <Form.Group className=" w-50" controlId="emailId">
                    <Form.Label>Email Id</Form.Label>
                    <Form.Control
                      name="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="text"
                      placeholder=""
                      disabled
                    />
                    {/* {errors.email && touched.email ? (
                        <label className="error">{errors.email}</label>
                      ) : null} */}
                  </Form.Group>
                  <Form.Group className=" w-50" controlId="createPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      placeholder=""
                      disabled
                    />
                    {/* {errors.password && touched.password ? (
                        <label className="error">{errors.password}</label>
                      ) : null} */}
                  </Form.Group>
                </div>
                <Form.Group className="" controlId="Description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                    rows={3}
                  />
                  {/* {errors.description && touched.description ? (
                      <label className="error">{errors.description}</label>
                    ) : null} */}
                </Form.Group>
                <div className="d-flex justify-content-end gap-3">
                  <button type="reset" className="custom-btn button-secondary ">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="custom-btn button-primary "
                    onClick={() => {
                      handleUpdateSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <div className="w-25 upload-image-section">
                <div className="img">
                  <div className="inner-img">
                    {profilePic ? (
                      <img src={profilePic.original} alt="" />
                    ) : (
                      <i className="fa fa-camera"></i>
                    )}
                  </div>
                </div>
                <Form.Group className="" controlId="uploadFile">
                  <Form.Label className="custom-btn button-secondary">
                    Upload Image
                  </Form.Label>
                  <Form.Control
                    className="bg-white d-none"
                    type="file"
                    placeholder=""
                    name="profilePic"
                    onChange={(e) => handleUploadImage(e)}
                  />
                  {/* {errors.profilePic && touched.profilePic ? (
                      <label className="error">{errors.profilePic}</label>
                    ) : null} */}
                </Form.Group>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </Layout>
    </>
  );
}
export default EditTeamMember;
