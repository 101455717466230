import React, { useState } from "react";
import "../pages/login/login.css";
import loginLogo from "../assets/images/login-logo.svg";
function LoginLayout({ children }) {
  return (
    <>
      <div className="main login-wrapper">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-12">
              <div className="card login-card text-center p-3">
                <img
                  className="login-logo"
                  width="240px"
                  heighr="183px"
                  src={loginLogo}
                />
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginLayout;
