import { React, useState } from "react";
import Settings from "../settings/settings";

import Form from "react-bootstrap/Form";
import InterviewerList from "./interviewerList";
import SlideOver from "../../components/slideOver/slideOver";
function Interviewers() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div>
                <h3 className="page-header">Interviewers(111)</h3>
              </div>
              <div>
                <button
                  onClick={() => setShow(true)}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Upload
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Interviewers"
                  content={<SlideOverContent />}
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="filter-bar">
                <button className="button-primary custom-btn">
                  Pre Screening
                </button>
                <button className="button-secondary">Pre Integration</button>
                <button className="button-secondary">Post Integration</button>
              </div>
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                  />
                </Form.Group>
                <i class="fa fa-search"></i>
              </div>
            </div>
            <div className="mt-3">
              <InterviewerList />
            </div>
          </div>
        </div>
      </Settings>
    </>
  );
  function SlideOverContent() {
    return (
      <>
        <div className="media-slide-over">
          <div className="image-section">
            <ul>
              <li>
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c8/Altja_j%C3%B5gi_Lahemaal.jpg/640px-Altja_j%C3%B5gi_Lahemaal.jpg"
                  alt=""
                />
              </li>
              <li>
                <Form.Group
                  className="slide-over-file-input"
                  controlId="slideOverImage"
                >
                  <Form.Label>
                    <i className="fa fa-camera" aria-hidden="true"></i>{" "}
                    Image/Video
                  </Form.Label>

                  <Form.Control
                    type="file"
                    name="picURL"
                    placeholder="Type here..."
                  />
                </Form.Group>
              </li>
            </ul>
          </div>
          <Form>
            <Form.Group className="my-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Title</Form.Label>
              <Form.Control type="text" placeholder="" />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Description</Form.Label>
              <Form.Control as="textarea" rows={7} />
            </Form.Group>

            <button className="custom-btn button-primary w-100 mt-4">
              Submit
            </button>
          </Form>
        </div>
      </>
    );
  }
}

export default Interviewers;
