import Layout from "../../../layout/layout";
import React, { useEffect, useState } from "react";

import AddProgramCard from "../addProgramCard";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";

import InputText from "../../../components/input/inputText";

import { useDispatch, useSelector } from "react-redux";
import { getVenue } from "../../../reduxSlice/venueSlice";
import InputDescription from "../../../components/input/inputDescription";
// import UploadImage from "../../../helperFunction/uploadImage";
import { addProgramstep3 } from "../../../reduxSlice/programSlice";

function Pricing() {
  const [activeButton, setActiveButton] = useState(null);
  const dispatch = useDispatch();
  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
  };

  const venues = useSelector((state) => state.venue.venues);

  const [programPricing, setProgramPricing] = useState([
    { price: "", title: "", type: "all days" },
  ]);
  const [addOn, setAddOn] = useState([{ price: "", description: "" }]);

  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState(null);
  const [discountStatus, setDiscountStatus] = useState("ACTIVE");
  const [beginsBefore, setBeginsBefore] = useState(null);
  const [endsBefore, setEndsBefore] = useState(null);
  const [discountMessage, setDiscountMessage] = useState(null);
  const [paymentPolicyInitial, setPaymentPolicyInitial] = useState(false);
  const [paymentPolicyType, setPaymentPolicyType] = useState("");
  const [preScreeningPercentage, setPreScreeningPercentage] = useState("");
  const [preIntegrationPercentage, setPreIntegrationPercentage] = useState("");
  const [donationHeading, setDonationHeading] = useState("");
  const [donationStatus, setDonationStatus] = useState("ACTIVE");
  const [donationDescription, setDonationDescription] = useState("");
  const [showNext, setShowNext] = useState(false);
  const [formValidation, setFormValidation] = useState(true);

  const handleProgramPricingInputChange = (index, event) => {
    const values = [...programPricing];
    values[index][event.target.name] = event.target.value;
    setProgramPricing(values);
  };

  const handleProgramPricing = () => {
    setProgramPricing([
      ...programPricing,
      { price: "", type: "all days", title: "" },
    ]);
  };
  const [venueData, setVenueData] = useState({});
  const handleVenueData = (id) => {
    var data = venues.data.filter((i) => i._id === id);
    setVenueData(data);
  };

  const handleRemoveProgramPricingFields = (index) => {
    const values = [...programPricing];
    values.splice(index, 1);
    setProgramPricing(values);
  };

  const handleAddOnInputChange = (index, event) => {
    const values = [...addOn];
    values[index][event.target.name] = event.target.value;
    setAddOn(values);
  };
  const handleAddOn = () => {
    setAddOn([...addOn, { price: "", quantity: "", description: "" }]);
  };
  const handleRemoveAddOnFields = (index) => {
    const values = [...addOn];
    values.splice(index, 1);
    setAddOn(values);
  };
  const handlePricingData = () => {
    var data = {
      programPricing: programPricing,
      venue: handleVenueData._id,
      addOns: addOn,
      discount: {
        type: discountType,
        message: discountMessage,
        value: discountValue,
        status: discountStatus,
        beginsBefore: beginsBefore,
        endsBefore: endsBefore,
      },
      paymentPolicy: {
        initial: paymentPolicyInitial,
        type: paymentPolicyType,
        preScreeningPercentage: preScreeningPercentage,
        preIntegrationPercentage: preIntegrationPercentage,
      },
      donation: {
        status: donationStatus,
        heading: donationHeading,
        description: donationDescription,
      },
    };
    if (!formValidation) {
      dispatch(addProgramstep3(data));
      setShowNext(true);
      alert("Submission is successfully");
    } else {
      alert("All fields are required");
    }
  };

  const buttons = [
    { id: 1, label: "Pre Screening", date: "24-30 June, 2023" },
    { id: 2, label: "Pre integration", date: "30-02 July, 2023" },
    { id: 3, label: "PROGRAM", date: "10-16 July, 2023" },
    { id: 4, label: "POST integration", date: "20-22 July, 2023" },
  ];
  const tabs = [
    {
      id: 1,
      name: "About Program",
      active: false,
      url: "/program/aboutProgram",
    },
    {
      id: 1,
      name: "Interviews & Forms",
      active: false,
      url: "/program/interviewsAndForms",
    },
    { id: 1, name: "Pricing", active: true, url: "/program/pricing" },
    {
      id: 1,
      name: "Registration & Email",
      active: false,
      url: "/program/registrationAndEmail",
    },
  ];

  const [images] = useState([
    {
      original:
        "https://heartofthejungle-api.techessentia.com/1698660979314client.png",
      thumbnail:
        "https://heartofthejungle-api.techessentia.com/1698660979314thumbnail_client.png",
    },
  ]);

  // const handleImageUpload = async (e, index) => {
  //   const imageURL = await UploadImage(e.target.files[0]);
  //   if (imageURL) {
  //     const values = [...addOn];
  //     values[index].image = imageURL;
  //     setAddOn(values);
  //   } else {
  //     console.error("Image upload failed");
  //   }
  // };

  useEffect(() => {
    dispatch(getVenue());
  }, [dispatch]);
  useEffect(() => {
    setFormValidation(
      !(
        programPricing &&
        venueData &&
        addOn &&
        discountType &&
        discountMessage &&
        discountValue &&
        discountStatus &&
        beginsBefore &&
        endsBefore &&
        paymentPolicyInitial &&
        paymentPolicyType &&
        preScreeningPercentage &&
        preIntegrationPercentage &&
        donationStatus &&
        donationHeading &&
        donationDescription
      )
    );
  }, [
    programPricing,
    venueData,
    addOn,
    discountType,
    discountMessage,
    discountValue,
    discountStatus,
    beginsBefore,
    endsBefore,
    paymentPolicyInitial,
    paymentPolicyType,
    preScreeningPercentage,
    preIntegrationPercentage,
    donationStatus,
    donationHeading,
    donationDescription,
  ]);
  return (
    <>
      <Layout>
        <div className="add-wrap our-team-layout">
          <div className="container">
            <div className="my-3">
              <div className="pagination">
                <Link to="/program">
                  <i class="fa fa-chevron-left"></i> Programs ·{" "}
                  <span>Detail Page</span>
                </Link>
              </div>
              <div className="select-buttons my-3">
                <div className="button-container">
                  {buttons.map((button) => (
                    <div key={button.id}>
                      <button
                        className={`button custom-btn ${
                          activeButton === button.id ? "active" : ""
                        }`}
                        onClick={() => handleButtonClick(button.id)}
                      >
                        {button.label}
                      </button>
                      <span className="button-date">{button.date}</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="program-details-tabs">
                <ul>
                  {tabs.map((tab) => (
                    <li key={tab.id} className={tab.active ? "active" : ""}>
                      <Link className={showNext ? "" : "pe-none"} to={tab.url}>
                        {tab.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handlePricingData()}
                  className="custom-btn button-primary"
                >
                  Save Changes
                </button>
              </div>

              {/* main content  */}

              <div className="d-flex gap-3 p-0 mt-3 pricing-layout">
                <div className="w-75 d-flex flex-column gap-3">
                  <AddProgramCard
                    header="Program Pricing"
                    body={
                      <div className="d-flex flex-column gap-3">
                        {programPricing.map((item, index) => (
                          <div key={index} className="d-flex gap-3">
                            <div className="d-flex align-items-center gap-1 w-25 flex-shrink-0">
                              <span>$</span>

                              <InputText
                                type="text"
                                name="price"
                                placeholder="00"
                                value={item.price}
                                numbers={true}
                                onChange={(event) =>
                                  handleProgramPricingInputChange(index, event)
                                }
                                required={true}
                              />
                            </div>

                            <Form.Select
                              value={item.type}
                              name="type"
                              onChange={(event) =>
                                handleProgramPricingInputChange(index, event)
                              }
                            >
                              <option value="all days">All Days</option>
                              <option value="per days">Per Days</option>
                            </Form.Select>
                            <button
                              type="button"
                              className="btn-icon"
                              onClick={() =>
                                handleRemoveProgramPricingFields(index)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </div>
                        ))}
                        <div className="d-flex">
                          <button
                            type="button"
                            onClick={(e) => handleProgramPricing(e)}
                            className="ms-auto plain-button"
                          >
                            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            Add New
                          </button>
                        </div>
                      </div>
                    }
                  />

                  <div>
                    <AddProgramCard
                      header="Select Venue & Rooms"
                      body={
                        <div>
                          {console.log(venueData)}
                          <Form.Select
                            className="w-50"
                            aria-label="Default select example"
                            onChange={(e) => handleVenueData(e.target.value)}
                          >
                            {venues.data ? (
                              venues.data.map((venue) => (
                                <>
                                  <option value={venue._id} key={venue._id}>
                                    {venue.name}
                                  </option>
                                </>
                              ))
                            ) : (
                              <></>
                            )}
                            {/* <option value="1">Package</option>
                            <option value="2">Flexible Dates</option>
                            <option value="3">Single Consultation</option> */}
                          </Form.Select>
                          {/* <div className="pricing-venue-list">
                            {[1, 2, 3].map((i) => (
                              <div
                                className="d-flex align-items-center gap-3 mt-3"
                                key={i}
                              >
                                <Form.Check
                                  type="checkbox"
                                  id={`checkbox${i}`}
                                />
                                <div
                                  id={`checkbox${i}`}
                                  className="venue-list gap-3"
                                >
                                  <img
                                    className="venue-img"
                                    src="https://img.freepik.com/free-photo/wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass_181624-22807.jpg"
                                    alt=""
                                  />
                                  <div className="venue-details flex-grow-1">
                                    <div className="d-flex justify-content-between">
                                      <strong>Room101</strong>
                                      <strong>
                                        $2222 <span>per night</span>
                                      </strong>
                                    </div>
                                    <p>Shared room with bathroom</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div> */}
                        </div>
                      }
                    />
                  </div>
                  <AddProgramCard
                    header="Add Ons"
                    body={
                      <div className="venue-add-ons">
                        <div className="add-venue-list">
                          <div className="d-flex flex-column gap-3">
                            {addOn.map((item, index) => (
                              <>
                                <div
                                  key={index}
                                  className="d-flex gap-5 align-items-center"
                                >
                                  <div className="d-flex flex-wrap gap-3">
                                    <InputText
                                      type="text"
                                      name="price"
                                      placeholder="Price"
                                      value={item.price}
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                      inputStyle="flex-grow-1"
                                      required={true}
                                      numbers={true}
                                    />
                                    <InputText
                                      type="text"
                                      name="quantity"
                                      placeholder="Quantity"
                                      inputStyle="w-50 flex-shrink-0"
                                      value={item.quantity}
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                      required={true}
                                      numbers={true}
                                    />
                                    <InputDescription
                                      inputStyle="flex-grow-1 w-100"
                                      value={item.description}
                                      name="description"
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                      rows={2}
                                      placeholder="Description here..."
                                      required={true}
                                    />
                                  </div>
                                  <div className="">
                                    <button
                                      onClick={() =>
                                        handleRemoveAddOnFields(index)
                                      }
                                      className="plain-button"
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="d-flex align-items-center gap-3">
                                <div
                                  style={{ width: "70%" }}
                                  className="flex-shrink-0"
                                >
                                  <div className="d-flex gap-3">
                                    <InputText
                                      inputStyle="w-50 flex-shrink-0"
                                      name="pickup"
                                      type="text"
                                      placeholder="Airport Pickup"
                                      value={item.pickup}
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                    />
                                    <InputText
                                      type="text"
                                      name="price"
                                      placeholder="100$"
                                      value={item.price}
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                    />
                                    <InputText
                                      type="text"
                                      name="quantity"
                                      placeholder="Max Quantity"
                                      inputStyle="w-25 flex-shrink-0"
                                      value={item.quantity}
                                      onChange={(event) =>
                                        handleAddOnInputChange(index, event)
                                      }
                                    />
                                  </div>
                                  <InputDescription
                                    inputStyle="flex-grow-0 mt-3"
                                    value={item.description}
                                    name="description"
                                    onChange={(event) =>
                                      handleAddOnInputChange(index, event)
                                    }
                                    rows={2}
                                    placeholder="Type Description here..."
                                  />
                                </div>
                                {item.image ? (
                                  <img
                                    src={item.image}
                                    alt=""
                                    style={{ width: "176px", height: "100px" }}
                                    className="rounded"
                                  />
                                ) : (
                                  <Form.Group
                                    className="input-file"
                                    controlId="uploadImage"
                                  >
                                    <Form.Label>
                                      <i className="fa fa-camera"></i>
                                    </Form.Label>

                                    <Form.Control
                                      type="file"
                                      name="picURL"
                                      onChange={(e) =>
                                        handleImageUpload(e, index)
                                      }
                                    />
                                  </Form.Group>
                                )}

                                <div className="">
                                  <button
                                    onClick={() =>
                                      handleRemoveAddOnFields(index)
                                    }
                                    className="plain-button"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div> */}
                              </>
                            ))}
                            <div className="d-flex gap-4 align-items-center">
                              <button
                                onClick={(e) => handleAddOn(e)}
                                className="plain-button text-decoration-underline"
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Add New
                              </button>
                              <button className="plain-button text-decoration-underline">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Existing
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  />

                  <div className="d-flex gap-3">
                    <div className="w-50">
                      <AddProgramCard
                        header="Discount"
                        body={
                          <div className="d-flex flex-column gap-2">
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <div className="d-flex gap-3 align-items-center">
                                <Form.Select
                                  onChange={(e) =>
                                    setDiscountType(e.target.value)
                                  }
                                  aria-label="Default select example"
                                >
                                  <option value="percentage">Percentage</option>
                                  <option value="flat price">Flat Price</option>
                                </Form.Select>
                                <InputText
                                  type="text"
                                  name="discountValue"
                                  placeholder="Value"
                                  onChange={(e) =>
                                    setDiscountValue(e.target.value)
                                  }
                                  required={true}
                                  numbers={true}
                                />

                                <span>
                                  {discountType === "flat price" ? "$" : "%"}
                                </span>
                              </div>
                            </Form.Group>
                            <Form.Group
                              className=""
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Status</Form.Label>
                              <Form.Select
                                onChange={(e) =>
                                  setDiscountStatus(e.target.value)
                                }
                                aria-label="Default select example"
                              >
                                <option value="ACTIVE">ACTIVE</option>
                                <option value="INACTIVE">INACTIVE</option>
                              </Form.Select>
                            </Form.Group>
                            <div className="d-flex gap-3">
                              <InputText
                                name="begins before"
                                label="Begins Before"
                                placeholder="Days..."
                                onChange={(e) =>
                                  setBeginsBefore(e.target.value)
                                }
                                required={true}
                                numbers={true}
                              />
                              <InputText
                                name="ends before"
                                label="Ends Before"
                                placeholder="Days..."
                                onChange={(e) => setEndsBefore(e.target.value)}
                                required={true}
                                numbers={true}
                              />
                            </div>
                            <InputDescription
                              rows={3}
                              label="Message"
                              name="message"
                              onChange={(e) =>
                                setDiscountMessage(e.target.value)
                              }
                              required={true}
                            />
                          </div>
                        }
                      />
                    </div>
                    <div className="w-50">
                      <AddProgramCard
                        header="1$ Booking"
                        className="h-100"
                        body={
                          <div className="d-flex flex-column gap-3">
                            <p className="booking-desc">
                              People need to pay <strong>$1</strong> for Pre
                              Screening Registration and then after approval of
                              Pre screening they can complete the booking.
                            </p>
                            <div className="d-flex gap-5">
                              <Form.Check
                                type="radio"
                                label="Yes"
                                id="yes"
                                name="yes-no"
                                value={true}
                                checked={paymentPolicyInitial === true}
                                onChange={(e) => setPaymentPolicyInitial(true)}
                              />
                              <Form.Check
                                type="radio"
                                label="No"
                                id="no"
                                name="yes-no"
                                value={false}
                                checked={paymentPolicyInitial === false}
                                onChange={(e) => setPaymentPolicyInitial(false)}
                              />
                            </div>
                            <Form.Group
                              className="custom-form-input"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>type of deposit</Form.Label>
                              <Form.Select
                                onChange={(e) =>
                                  setPaymentPolicyType(e.target.value)
                                }
                                aria-label="Default select example"
                              >
                                <option>Pay in installments</option>
                                <option>Package</option>
                                <option>Flexible Dates</option>
                                <option>Single Consultation</option>
                              </Form.Select>
                            </Form.Group>
                            <div className="d-flex align-items-center gap-3">
                              <div className="w-25 d-flex align-items-center gap-3">
                                <span>%</span>
                                <InputText
                                  type="text"
                                  onChange={(e) =>
                                    setPreScreeningPercentage(e.target.value)
                                  }
                                  required={true}
                                  numbers={true}
                                />
                              </div>
                              <div className="w-75">
                                <label htmlFor="">
                                  Pre Screening Percentage
                                </label>
                              </div>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                              <div className="w-25 d-flex align-items-center gap-3">
                                <span>%</span>
                                <InputText
                                  type="text"
                                  onChange={(e) =>
                                    setPreIntegrationPercentage(e.target.value)
                                  }
                                  required={true}
                                  numbers={true}
                                />
                              </div>
                              <div className="w-75">
                                <label htmlFor="">
                                  Pre Integration Percentage
                                </label>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <AddProgramCard
                      body={
                        <div>
                          <div className="d-flex gap-3">
                            <Form.Group
                              className="flex-grow-1"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Form.Label>Donation Status</Form.Label>
                              <Form.Select
                                onChange={(e) =>
                                  setDonationStatus(e.target.value)
                                }
                                aria-label="Default select example"
                              >
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">InActive</option>
                              </Form.Select>
                            </Form.Group>

                            <InputText
                              label="Heading"
                              inputStyle="flex-grow-1"
                              type="text"
                              onChange={(e) =>
                                setDonationHeading(e.target.value)
                              }
                              required={true}
                            />
                          </div>
                          <InputDescription
                            lable="Description"
                            rows={3}
                            inputStyle="mt-3"
                            onChange={(e) =>
                              setDonationDescription(e.target.value)
                            }
                            required={true}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="d-flex justify-content-end gap-3">
                    <Link
                      className="custom-btn button-secondary "
                      to="/program/interviewsAndForms"
                    >
                      Back
                    </Link>
                    {showNext ? (
                      <Link
                        className="custom-btn button-primary "
                        to="/program/registrationAndEmail"
                      >
                        Next
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="w-25 d-flex flex-column gap-3">
                  <AddProgramCard
                    header="Registrations"
                    body={
                      <div>
                        <div className="d-flex gap-2 align-items-center registration">
                          <div className="images">
                            {[1, 2, 3, 4].map((item) => (
                              <img
                                key={item}
                                src="https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                alt=""
                              />
                            ))}
                          </div>
                          <span>24 People Registered</span>
                        </div>
                      </div>
                    }
                  />
                  <AddProgramCard
                    header="Featured Images/Videos"
                    body={
                      <div className="add-program-feature">
                        <div className="images">
                          <ul>
                            {images.map((e, i) => {
                              return (
                                <li key={i}>
                                  <img src={e.original} alt="" />
                                </li>
                              );
                            })}
                            <li>
                              <Form.Group
                                className="input-file"
                                controlId="uploadImage"
                              >
                                <Form.Label>
                                  <i className="fa fa-camera"></i>
                                </Form.Label>

                                <Form.Control
                                  type="file"
                                  name="picURL"
                                  //   onChange={(e) =>
                                  //     uploadImage(
                                  //       e.target.files[0],
                                  //       setFieldValue
                                  //     )
                                  //   }
                                  //   onBlur={handleBlur}
                                  placeholder="Type here..."
                                />
                              </Form.Group>
                            </li>
                          </ul>
                          <p className="card-desc m-0">
                            Click the image to edit or update
                          </p>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default Pricing;
