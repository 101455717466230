import { React, useEffect, useState } from "react";
import Settings from "../settings/settings";

import Form from "react-bootstrap/Form";
import SlideOver from "../../components/slideOver/slideOver";
import InputText from "../../components/input/inputText";
import InputDescription from "../../components/input/inputDescription";
import { useDispatch, useSelector } from "react-redux";
import { addDocument, getDocument } from "../../reduxSlice/documentSlice";
import { Link } from "react-router-dom";
import FormsList from "./formsList";
function Forms() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("form");

  const [formValidation, setFormValidation] = useState(true);
  const [showError, setShowError] = useState("");

  const forms = useSelector((state) => state.document.documents);
  const [searchForms, setSearchForms] = useState({
    type: "form",
    search: "",
  });

  const handleSubmitForm = (e) => {
    e.preventDefault();
    var data = {
      title: title,
      type: type,
      description: description,
    };
    if (!formValidation) {
      dispatch(addDocument(data));
      setTimeout(() => {
        dispatch(getDocument(searchForms));
      }, 200);
      setShowError("");
    } else {
      setShowError("All fields are required");
    }
  };
  const handleSearch = (value) => {
    setSearchForms({
      ...searchForms,
      search: value,
    });
  };
  useEffect(() => {
    dispatch(getDocument(searchForms));
  }, [dispatch, searchForms]);

  useEffect(() => {
    setFormValidation(!(title && description && type));
  }, [title, description, type]);
  return (
    <>
      <Settings>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center gap-3 document-header">
                <Link
                  to="/settings/documents"
                  className="page-header opacity-50"
                >
                  Documents
                </Link>
                <Link to="/settings/forms" className="page-header ">
                  Forms
                </Link>
              </div>
              <div>
                <button
                  onClick={() => setShow(true)}
                  className="custom-btn button-primary"
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </button>
                <SlideOver
                  show={show}
                  handleClose={handleClose}
                  header="Add Documents/Forms"
                  content={
                    <div className="media-slide-over">
                      <Form>
                        <InputText
                          label="Title"
                          required={true}
                          type="text"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        <InputDescription
                          label="Description"
                          required={true}
                          rows={4}
                          onChange={(e) => setDescription(e.target.value)}
                          inputStyle="my-3"
                        />

                        <Form.Group className="" controlId="firstName">
                          <Form.Label>Type</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="document">Document</option>
                            <option defaultValue value="form">
                              Form
                            </option>
                          </Form.Select>
                        </Form.Group>
                        {showError ? (
                          <small className="form-error">{showError}</small>
                        ) : (
                          <></>
                        )}
                        <button
                          type="submit"
                          onClick={(e) => handleSubmitForm(e)}
                          className="custom-btn button-primary w-100 mt-3"
                        >
                          Submit
                        </button>
                      </Form>
                    </div>
                  }
                />
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout">
              <div className="search-bar">
                <Form.Group
                  className="form-group "
                  controlId="ProgramInputSearch"
                >
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Search by name..."
                    onChange={(e) => handleSearch(e.target.value)}
                  />
                </Form.Group>
                <i class="fa fa-search"></i>
              </div>
            </div>
            <div className="mt-3">
              <FormsList forms={forms} />
            </div>
          </div>
        </div>
      </Settings>
    </>
  );
}

export default Forms;
