import React from "react";
import "./login.css";

import LoginC from "../../components/login/loginC";
import LoginLayout from "../../layout/loginLayout";
function Login() {
  return (
    <>
      <LoginLayout>
        <LoginC />
      </LoginLayout>
    </>
  );
}

export default Login;
