import React from "react";
import Layout from "../../layout/layout";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import UsersLayout from "./usersLayout";
import { useEffect } from "react";
import { getUsers } from "../../reduxSlice/usersSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

function Users() {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const [onSearch, setOnSearch] = useState("");
  useEffect(() => {
    dispatch(getUsers(onSearch));
  }, [dispatch, onSearch]);
  return (
    <>
      <Layout>
        <div className="add-wrap">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between my-3">
              <div>
                <h3 className="page-header">Registrations</h3>
              </div>
              <div>
                <Link to="/users/addUser" className="custom-btn button-primary">
                  {" "}
                  <i className="fa fa-plus" aria-hidden="true"></i> Add New
                </Link>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between page-header-layout mb-2">
              <div className="filter-bar">
                <div className="search-bar">
                  <Form.Group
                    className="form-group "
                    controlId="ProgramInputSearch"
                  >
                    <Form.Control
                      type="text"
                      name="title"
                      placeholder="Search by name..."
                      onChange={(e) => setOnSearch(e.target.value)}
                    />
                  </Form.Group>
                  <i class="fa fa-search"></i>
                </div>
              </div>
              <div className="d-flex gap-3">
                <button className="button-secondary">
                  Filter <i class="fa fa-sliders"></i>
                </button>
                <button className="button-secondary">
                  Sort By <i class="fa fa-sort"></i>
                </button>
              </div>
            </div>
            <UsersLayout users={users} />
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Users;
